import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import routes from '../../Constants/routes'
import customerActions from '../../Actions/customerActions'
import { withTranslation } from 'react-i18next';
import commonActions from '../../Actions/commonActions'
import { useNavigate } from 'react-router-dom';
import OldCustomerForm from "../../Components/Customer/OldCustomerForm";

function CustomerFormContainer({ fromMainpage, vbCustomer, t, customerForm, customerActions, customerConnections }) {

    const navigate = useNavigate();

    useEffect(() => {
        if (vbCustomer.isNonInteractive) {
            navigate(routes.customerMain(vbCustomer.customerNumber));
        }
        
        if (!customerConnections)
            customerActions.getCustomerConnections(vbCustomer.customerNumber);
        
        customerActions.showCustomerEditForm(vbCustomer);
    }, [vbCustomer])

    const navigateAfterAction = () => {
        return () => navigate(routes.customerMain(vbCustomer.customerNumber));
    }

    const isMainPage = fromMainpage ? fromMainpage : false;

    if (!customerForm) {
        return null;
    }
    
    return (
        <div className="panel">
            <div className="panel-heading">
                <div className="panel-title">{t("leftnavigation.editcustomer")}</div>
            </div>
            <div className="panel-body">
                <div className='left-customer-col'>
                    <OldCustomerForm callback={navigateAfterAction()} isMainPage={isMainPage} isCreation={false} />
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customerConnections: state.CustomerReducer.customerConnections,
        customerForm: state.CommonReducer.forms && state.CommonReducer.forms.customerFormData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerFormContainer));