import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next';
import Loader from '../../Components/Loader'
import TextInput from '../../Components/TextInput'
import routes from '../../Constants/routes'
import customerActions from '../../Actions/customerActions'
import commonActions from '../../Actions/commonActions'
import { useNavigate } from 'react-router-dom';
import Card from "../../Components/Card/Card";


function VonAccountFormContainer({ customerActions, vbCustomer, customer, commonActions, createVonAccountForm }) {
    const navigate = useNavigate();

    const {t} = useTranslation();


    useEffect(() => {
        customerActions.showVonAccountForm(createFormData(vbCustomer), customer.oldVbCustomer);
    }, [])

    const navigateAfterAction = () => {
        commonActions.setOpenHeader(undefined)
        return () => navigate(routes.customerMain(customer.pathId));
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField(createVonAccountForm.id, field, value)
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(createVonAccountForm.id, navigateAfterAction())
    }

    const onSaveClick = () => {
        customerActions.saveVonAccount(createVonAccountForm, navigateAfterAction())
    }

    const createFormData = (customer) => {
        return {
            customerNumber: customer.customerNumber,
            firstName: '',
            lastName: '',
            emailAddress: customer.emailAddress,
            contactPerson: customer.contactPerson,
            streetAddress: customer.streetAddress,
            postalCode: customer.postalCode,
            postalCity: customer.postalCity,
            phoneNumber: customer.phoneNumber
        }
    }

    if (!customerActions.isCustomerValid)
        return null;

    if (!createVonAccountForm)
        return null;

    const vonUser = createVonAccountForm.data;

    return (<Card>
        <Card.Header>
            <Card.Header.Title>{t('vonaccountscontainer.newvonaccount')}</Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <div className="row">
                <div className="col-md-12 col-lg-8">
                    <div className="form-horizontal">
                        <TextInput label={t('vonaccountformcontainer.firstname')}
                                   onChange={onFieldChange.bind(this, 'firstName')} value={vonUser.firstName}/>
                        <TextInput label={t('vonaccountformcontainer.lastname')}
                                   onChange={onFieldChange.bind(this, 'lastName')} value={vonUser.lastName}/>
                        <TextInput label={t('vonaccountformcontainer.contactperson')}
                                   onChange={onFieldChange.bind(this, 'contactPerson')} value={vonUser.contactPerson}/>
                        <TextInput label={t('vonaccountformcontainer.streetaddress')}
                                   onChange={onFieldChange.bind(this, 'streetAddress')} value={vonUser.streetAddress}/>
                        <TextInput label={t('vonaccountformcontainer.postalcode')}
                                   onChange={onFieldChange.bind(this, 'postalCode')} value={vonUser.postalCode}/>
                        <TextInput label={t('vonaccountformcontainer.postalcity')}
                                   onChange={onFieldChange.bind(this, 'postalCity')} value={vonUser.postalCity}/>
                        <TextInput label={t('vonaccountformcontainer.phonenumber')}
                                   onChange={onFieldChange.bind(this, 'phoneNumber')} value={vonUser.phoneNumber}/>
                        <TextInput label={t('vonaccountformcontainer.email')}
                                   onChange={onFieldChange.bind(this, 'emailAddress')} value={vonUser.emailAddress}/>
                    </div>
                </div>
            </div>
        </Card.Content>
        <Card.Footer>
            <Card.Footer.Buttons>

                <div className="form-group padding-top no-left-margin btn-toolbar">
                    <button type="button" className="btn btn-primary"
                            onClick={onSaveClick.bind(this)}>{t('vonaccountformcontainer.save')}</button>
                    <button type="button" className="btn btn-default"
                            onClick={onCancelClick.bind(this)}>{t('vonaccountformcontainer.cancel')}</button>
                    <Loader/>
                </div>
            </Card.Footer.Buttons>
        </Card.Footer>
    </Card>)
}

function mapStateToProps(state) {
    return {
    createVonAccountForm: state.CommonReducer.forms && state.CommonReducer.forms.createVonAccount,
    vbCustomer: state.CustomerReducer.selectedCustomer,
    customer: state.CustomerReducer.customer
    }
}

function mapDispatchToProps(dispatch) {
    return {
    customerActions: bindActionCreators(customerActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(VonAccountFormContainer);