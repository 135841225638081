import Modal from "../../../../Components/Modal";
import { VossTierSelector } from "../../../../Components/OrderPlacement/ProductComponents/Voss/VossTierSelector";

export function ChangeSubscriptionTierContainer({ ...props }) {
    const { subscriptionPackage, subscriptionForm, onCloseClick, onChange } = props;

    const modalProps = {
        title: "Select tier",
        stateful: true,
        open: true,
        dialogStyle: {width: "65%"},
        disableMainButton: true,
        customClassName: 'bg-primary',
        onCancelClick: () => onCloseClick('selectTier'),
        showButtons: false
    }

    return <Modal {...modalProps}>
        <div className="mx-64">
            <VossTierSelector tiers={subscriptionPackage.tiers} onSelect={(value) => {
                onChange(subscriptionForm.id, value, 'tierId');
                onChange(subscriptionForm.id, subscriptionPackage.chargePlans.find(cp => cp.tierId === value && cp.planId === subscriptionForm.data.planId)?.planId ?? -1, 'planId');
                onCloseClick("selectTier");
            }} />
        </div>
    </Modal>
}