import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import helpers from "../../Utils/helpers";
import SkynetList from "../Lists/SkynetList";
import { useNavigate } from 'react-router-dom';
import routes from '../../Constants/routes';
import SkynetIcon from '../Fundamentals/SkynetIcon';
import infoicon from '../../Images/infoicon.svg'
import { VossProductGroupType } from '../../Constants/VOSS/vossProductGroupType';
import { VossProductType } from '../../Constants/VOSS/vossProductType';
import Tooltip, { TooltipContent, TooltipTrigger } from '../Overlays/Tooltip';

const Subscriptions = ({ subscriptions, onButtonClick, customer, vbCustomer }) => {
    const [currentSubscription, setCurrentSubscription] = React.useState("");
    const { t } = useTranslation();
    const navigate = useNavigate();

    if (!subscriptions || subscriptions.length < 1)
        return

    const toggleCurrentSubscription = (id) => {
        if (currentSubscription === id) {
            setCurrentSubscription("")
        } else {
            setCurrentSubscription(id)
        }
    }

    const terminateSubscription = (e, subscription) => {
        e.stopPropagation();
        onButtonClick('terminate', subscription)
    }

    const renderProductGroups = (productGroups) => {
        let addons = productGroups?.filter((group) => group.productGroupType === VossProductGroupType.Optional)?.map((group) => group.products)
        let products = productGroups?.filter((group) => group.productGroupType === VossProductGroupType.Included)?.map((group) => group.products.filter(p => p.type !== VossProductType.Usage));

        return <div className="ml-8">
            {products.length > 0 && products.map((group, index) => {
                return <SkynetList key={`products-${index}`} title={t('subscriptions.includedproducts')} listItems={group} />
            })}
            {addons.length > 0 && <>
                <h4 className="bold pt-8">{t('subscriptions.optionalproducts')}</h4>
                {addons.map((group, index) => <SkynetList key={`addons-${index}`} listItems={group} />)}
            </>}
        </div>
    }

    const renderTerminationColumn = (subscription) => {
        if (!subscription.termination) {
            return helpers.isAoCustomer(vbCustomer) 
                ? <span>-</span> 
                : (
                    <a 
                        className="text-nowrap"
                        onClick={(e) => terminateSubscription(e, subscription)}
                    >
                        {t('subscriptions.cancelagreement')}
                    </a>
                  );
        }
    
        return (
            <span>
                {subscription.termination.reason?.reasonName} {subscription.termination.reason?.comment}
            </span>
        );
    };
    
    const renderEditColumn = (subscription) => {
        return <div className="d-flex justify-content-center">
            <SkynetIcon icon={'vismaicon-edit'}
                onClick={() => { navigateToEdit(subscription.id) }} />
        </div>
    }

    const navigateToEdit = (subscriptionId) => {
        const params = new URLSearchParams();
        params.append("SubscriptionId", subscriptionId);
        navigate(routes.customerSubscriptionsEdit(customer.vossCustomer.id) + "?" + params.toString())
    }

    return <div className="table-responsive">
        <table className="table table-stripe table-hover">
            <thead>
                <tr key="thead">
                    <th width="200" className="text-left">{t('subscriptions.package')}</th>
                    <th width="130" className="text-left">{t('subscriptions.tier')}</th>
                    <th width="130" className="text-left">{t('subscriptions.users')}</th>
                    <th width="100" className="text-left">{t('subscriptions.purchasedate')}</th>
                    <th width="100" className="text-left">{t('subscriptions.enddate')}</th>
                    <th width="150" className="text-left">{t('subscriptions.period')}</th>
                    <th width="50" className="text-center">{t('subscriptions.edit')}</th>
                    <th width="200" className="text-left">
                    <div className="d-flex">
                    {t('subscriptions.termination')}
                            {helpers.isAoCustomer(vbCustomer) && 
                                <Tooltip>
                                    <TooltipTrigger>
                                        {<>
                                            <img className="ml-8" src={infoicon} alt="Info"/>
                                        </>}
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        {t('subscriptions.aocustomer')}
                                    </TooltipContent>
                                </Tooltip>}
                            </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {subscriptions.map(subscription => {
                    let licenses = 0;
                    subscription.licenses?.map((license) => licenses += license.quantity);
                    return <Fragment key={subscription.id}>
                        <tr onClick={() => toggleCurrentSubscription(subscription.id)}
                            className="cursorpointer">
                            <td className={`skynet-caret-toggle${currentSubscription === subscription.id ? " toggle" : ""}`}>
                                <span className="caret mr-12 mb-2"></span>{subscription.package.name}
                            </td>
                            <td>{subscription.tier.name}</td>
                            <td>{licenses > 0 ? licenses : ""}</td>
                            <td>{helpers.formatDate(subscription.created)}</td>
                            <td>{helpers.formatDate(subscription.bindingPeriod.endDate)}</td>
                            <td>{subscription.plan.name}</td>
                            <td>{renderEditColumn(subscription)}</td>
                            <td>{renderTerminationColumn(subscription)}</td>
                        </tr>
                        {currentSubscription === subscription.id &&
                            <tr className={`skynet-top-border skynet-expandable-table-bg`}>
                                <td colSpan={8} className="pl-24 pt-12 pb-12 skynet-expandable-table-bg">
                                    {renderProductGroups(subscription.productGroups)}
                                </td>
                            </tr>
                        }
                    </Fragment>
                })}
            </tbody>
        </table>
    </div>
}
export default Subscriptions;