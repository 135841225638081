import { VossProductGroupTagType } from "../../../../Constants/VOSS/vossProductGroupTagType";
import { VossProductGroupType } from "../../../../Constants/VOSS/vossProductGroupType";
import { Checkbox } from "../../../../Components/FormComponents/Checkbox";
import { useState } from "react";
import { Dropdown } from "../../../FormComponents/Dropdown";

export function ActivateSubscriptionProductGroups({ ...props }) {
    const { subscription, subscriptionProductGroups, subscriptionPackage } = props;

    let tierProductGroups = subscriptionPackage.tiers.find(x => x.id === subscription.tier.id).productGroups.filter(x => x.productGroupType === VossProductGroupType.Optional)

    const prepareGroupedProductGroups = (groups, subscriptionProductGroups) => {
        const tagMap = {};

        groups.forEach(group => {
            group.tags
                .filter(tag => tag.type === VossProductGroupTagType.Exclusive)
                .forEach(tag => {
                    if (!tagMap[tag.tag]) {
                        tagMap[tag.tag] = [];
                    }
                    tagMap[tag.tag].push(group);
                });
        });

        return Object.values(tagMap).filter(group =>
            !group.some(x => subscriptionProductGroups.some(y => y.id === x.id))
        );
    };

    const groupedProductGroups = prepareGroupedProductGroups(tierProductGroups, subscriptionProductGroups);

    return (
        <div>
            <h3>Activate product groups</h3>
            <fieldset className="d-grid gap-8">
                {groupedProductGroups.length > 0 ? (
                    groupedProductGroups.map(group => (
                        <ProductGroupCheckbox key={group[0]?.id} group={group} {...props} />
                    ))
                ) : (
                    <em>The customer already has all available product groups</em>
                )}
            </fieldset>
        </div>
    );
}

function ProductGroupCheckbox({ group, productGroupsForm, onChange }) {
    const [selectedProduct, setSelectedProduct] = useState(group[0].id);

    const onCheck = (checked, value) => {
        if (checked) {
            const productGroups = [...productGroupsForm.data.productGroups, value];
            onChange(productGroupsForm.id, productGroups, 'productGroups');
        }
        else {
            const productGroups = productGroupsForm.data.productGroups.filter(x => x !== value);
            onChange(productGroupsForm.id, productGroups, 'productGroups');
        }
    }

    if (group.length === 1) {
        return <div className="col-3 pl-0">
            <Checkbox label={group[0].name} id={group[0].id} onChange={onCheck} checked={productGroupsForm.data.productGroups.find(x => x === group[0].id)} />
        </div>
    }

    return <div className="d-flex align-items-center">
        <div className="d-flex">
            <Checkbox
                id={selectedProduct}
                onChange={onCheck}
                checked={productGroupsForm.data.productGroups.find(x => x === selectedProduct)}
            />
        </div>
        <div className="pl-0 col-3">
            <Dropdown options={group}
                value={selectedProduct}
                onChange={(value) => setSelectedProduct(value)}
            />
        </div>
    </div>
}