import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormInput } from "../../FormComponents/Input/Input";
import SkynetIcon from "../../Fundamentals/SkynetIcon";
import routes from "../../../Constants/routes";
import { useTranslation } from "react-i18next";
import {DynamicInputTable} from "../../FormComponents/Input/DynamicInputTable";

export function TerminationReasonForm({ terminationReasonForm, terminationReasons, terminationReasonsActions, isEdit, commonActions }) {
    const [subReasonForm, setSubReasonForm] = useState({name: '', externalId: null});
    const navigate = useNavigate();
    const {t} = useTranslation();

    const onChange = (value, key) => {
        commonActions.changeMultiFormField('terminationReasonForm', key, value);
    };

    const onSave = () => {
        const action = isEdit ? terminationReasonsActions.updateTerminationReason : terminationReasonsActions.createTerminationReason;
        action(terminationReasonForm, () => navigate(routes.icaTerminationReasons));
    };

    const onAddSubreason = () => {
        if (!subReasonForm.name) return;

        const newSubReason = {name: subReasonForm.name, externalId: subReasonForm.externalId};
        if (isEdit) {
            terminationReasonsActions.createTerminationReason(
                {data: {...newSubReason, parentId: terminationReasonForm.data.id}},
                () => terminationReasonsActions.getTerminationReasonForEdit(terminationReasonForm.data.id)
            );
        } else {
            onChange([...terminationReasonForm.data.subReasons, newSubReason], "subReasons");
        }

        setSubReasonForm({name: '', externalId: null});
    };

    const onDeleteSubreason = (index) => {
        const updatedSubReasons = terminationReasonForm.data.subReasons.filter((_, i) => i !== index);
        if (isEdit) {
            terminationReasonsActions.deleteSubTerminationReason(
                terminationReasonForm.data.subReasons[index].id,
                terminationReasonForm.data.id,
                () => terminationReasonsActions.getTerminationReasonForEdit(terminationReasonForm.data.id)
            );
        } else {
            onChange(updatedSubReasons, "subReasons");
        }
    };

    const headRows = <>
        <td>*{t('terminationreasonsform.subreason')}</td>
        <td>{t('terminationreasonsform.externalid')}</td>
    </>

    const inputRows = <>
        <td className="p-12">
            <FormInput
                value={subReasonForm.name}
                placeholder={t('terminationreasonsform.subreason')}
                onChange={(value) => setSubReasonForm({...subReasonForm, name: value})}
            />
        </td>
        <td className="p-12">
            <FormInput
                value={subReasonForm.externalId}
                placeholder={t('terminationreasonsform.externalid')}
                onChange={(value) => setSubReasonForm({...subReasonForm, externalId: value || null})}
            />
        </td>
    </>

    function currentRows(subReason) {
        return <>
            <td>{subReason.name}</td>
            <td>{subReason.externalId}</td>
        </>

    }

    const renderMainReasonDropdown = () => (
        <div className="form-control skynet-form-input">
            <label>{t('terminationreasonsform.mainreason')}</label>
            <select
                className="form-control"
                onChange={(e) => onChange(e.target.value, 'parentId')}
                value={terminationReasonForm.data.parentId}
            >
                <option value="">{t('terminationreasonsform.choosemainreason')}</option>
                {terminationReasons.map((reason, index) => (
                    <option key={index} value={reason.id}>{reason.name}</option>
                ))}
            </select>
        </div>
    );

    return (
        <div>
            <div className="col-12">
                <div className="d-flex flex-column" style={{gap: "24px"}}>
                    <div className="d-flex flex-column col-4 pl-0" style={{gap: "24px"}}>
                        <FormInput
                            label={t('terminationreasonsform.name')}
                            value={terminationReasonForm.data.name}
                            onChange={(value) => onChange(value, 'name')}
                            required
                        />
                        <FormInput
                            label={t('terminationreasonsform.externalid')}
                            value={terminationReasonForm.data.externalId}
                            onChange={(value) => onChange(value || null, 'externalId')}
                        />
                        {terminationReasons && !isEdit && !terminationReasonForm.data.subReasons.length && renderMainReasonDropdown()}
                    </div>
                    {!terminationReasonForm.data.parentId && <div className="col-4 pl-0">
                        <DynamicInputTable headRows={headRows} inputRow={inputRows}
                                           current={terminationReasonForm.data.subReasons}
                                           currentRow={currentRows}
                                           onAdd={onAddSubreason}
                                           onDelete={onDeleteSubreason}
                                           currentTitle={"Current subreasons"}
                                           disableAdd={!subReasonForm.name}/>
                    </div>}
                </div>
            </div>
            <div className="mt-24">
                <button className="btn btn-primary" onClick={onSave}>{t('terminationreasonsform.save')}</button>
                {isEdit && (
                    <button className="btn btn-primary btn-destructive"
                            onClick={() => terminationReasonsActions.deleteTerminationReason(terminationReasonForm.data.id, () => navigate(routes.icaTerminationReasons))}>Radera</button>
                )}
                <button className="btn btn-secondary"
                        onClick={() => navigate(routes.icaTerminationReasons)}>{t('terminationreasonsform.cancel')}</button>
            </div>
        </div>
    );
}
