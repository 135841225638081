import { VossProductGroupType } from "../../../../Constants/VOSS/vossProductGroupType";
import { VossSubscriptionItemStatus } from "../../../../Constants/VOSS/vossSubscriptionItemStatus";
import helpers from "../../../../Utils/helpers";

export function ActiveSubscriptionProductGroups({ ...props }) {
    const { subscription, subscriptionProductGroups, subscriptionActions } = props;

    const onTerminate = (productGroupId) => {
        subscriptionActions.terminateSubscriptionProductGroup(subscription.id, productGroupId, () => {
            subscriptionActions.getSubscriptionProductGroups(subscription.id, VossSubscriptionItemStatus.Active);
        });
    }

    const onDeleteTermination = (productGroupId) => {
        subscriptionActions.deleteProductGroupTermination(subscription.id, productGroupId, () => {
            subscriptionActions.getSubscriptionProductGroups(subscription.id, VossSubscriptionItemStatus.Active);
        });
    }

    let activeProductGroups = subscriptionProductGroups.filter(x => x.productGroupType != VossProductGroupType.Included)

    return <div>
            <h3>Active product groups</h3>
            {activeProductGroups.length > 0 ? <table className="table table-stripe skynet-table-no-divider mb-0">
                <thead>
                    <tr>
                        <th colSpan={1}>Product group</th>
                        <th colSpan={1}>Start date</th>
                        <th colSpan={5} className="col-5">Termination</th>
                    </tr>
                </thead>
                <tbody>
                    {activeProductGroups.map((productGroup) => {
                        return <tr key={productGroup.id}>
                            <td>{productGroup.name}</td>
                            <td>{helpers.formatDateAndTime(productGroup.created)}</td>
                            <td>{productGroup.termination ? <>Termination scheduled for {helpers.formatDate(productGroup.termination.scheduledDate)} - <a onClick={() => onDeleteTermination(productGroup.id)}>Cancel termination</a></> : <a onClick={() => onTerminate(productGroup.id)}>Cancel product group</a>}</td>
                        </tr>
                    })}
                </tbody>
            </table> :
                <div>
                    <em>The customer has no active product groups</em>
                </div>}
        </div>
}