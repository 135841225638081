import { Dropdown } from "../../../FormComponents/Dropdown";
import { FormInput } from "../../../FormComponents/Input/Input";
import { IntermediarySelector } from "../../../Voss/IntermediarySelector";
import { VossScheduledChangeStatus } from "../../../../Constants/VOSS/vossScheduledChangeStatus";

export function EditSubscriptionForm({ ...props }) {
    const { subscriptionPackage, subscriptionForm, subscription, onChange, intermediaries, onOpenModal } = props;

    const chargePlans = subscriptionPackage.chargePlans.filter(x => x.tierId === subscriptionForm.data.tierId).map(cp => cp.planId);
    const selectablePlans = subscriptionPackage.plans.filter(x => chargePlans.includes(x.id));
    const scheduledChange = subscription.scheduledChanges.find(x => x.status === VossScheduledChangeStatus.Pending);

    return <div className="d-grid gap-24 col-5 pl-0">
        <div>
            <fieldset className="d-grid col gap-16 mb-0 pl-0" disabled={scheduledChange?.tier}>
                <legend className="mb-12">Tier</legend>
                <div className="d-flex gap-12">
                    <FormInput value={subscriptionPackage.tiers.find(x => x.id === subscriptionForm.data.tierId)?.name} disabled={true} />
                    <button className="btn btn-primary mb-0" onClick={() => onOpenModal('selectTier')}>Select tier</button>
                </div>
            </fieldset>
        </div>
        <div>
            <fieldset className="d-grid col gap-16 mb-0 pl-0" disabled={scheduledChange?.plan}>
                <legend className="mb-12">Plan</legend>
                <Dropdown options={selectablePlans} value={subscriptionForm.data.planId} onChange={(value) => onChange(subscriptionForm.id, value, 'planId')} property="name" valueProperty='id' />
            </fieldset>
        </div>
        {intermediaries && <div>
            <fieldset className="d-grid col gap-16 mb-0 pl-0">
                <legend className="mb-12">Intermediary</legend>
                <IntermediarySelector {...props} onChange={(value) => onChange(subscriptionForm.id, value, 'intermediaryInvoicingId')} value={subscriptionForm.data.intermediaryInvoicingId} />
            </fieldset>
        </div>}
    </div>
}