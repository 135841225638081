import {useTranslation, withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import Modal from "../../Modal";
import {ActionType} from "../../../Constants/actionType";
import {bindActionCreators} from "redux";
import commonActions from "../../../Actions/commonActions";
import customerActions from "../../../Actions/customerActions";
import {connect} from "react-redux";
import TargetGroupActions from "../../../Actions/Voss/targetGroupActions";
import {CustomerForm} from "./CustomerForm";

function EditCustomerModal ({form, onCloseClick, customer, commonActions, customerActions, dropdownlists, actionType, user, dataLoading, targetGroupsActions, targetGroups, customerConnections }) {
    const {t} = useTranslation();
    
    useEffect(() => {
        if (!form)
            customerActions.getSelectedCustomerForEdit(customer.vbCustomer.customerNumber, "customerFormData");
        
        if (!customerConnections)
            customerActions.getCustomerConnections(customer.vbCustomer.customerNumber);

        if (!targetGroups)
            targetGroupsActions.getTargetGroups();
    }, []);

    const modalProps = {
        title: `${t(`editcustomermodal.${actionType}`)} ${customer?.vossCustomer?.name ?? customer?.vbCustomer?.name}`,
        stateful: true,
        open: true,
        bodyHeight: 200,
        onCancelClick: () => onCloseClick(form.id),
        onSaveClick: () => customerActions.saveVossCustomer(form, actionType, onCloseClick),
        mainButtonTitle: t("common.save"),
        customClassName: "modal-xl",
        showButtons: !dataLoading
    }

    return <Modal {...modalProps}>
            <CustomerForm commonActions={commonActions}
                          customerActions={customerActions}
                          actionType={actionType}
                          form={form}
                          user={user}
                          dataLoading={dataLoading || !form || !user} 
                          dropdownlists={dropdownlists}
                          customerConnections={customerConnections}/>
    </Modal>
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.customer,
        user: state.CommonReducer.user,
        form: state.CommonReducer.forms && state.CommonReducer.forms.customerFormData,
        dropdownlists: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists,
        dataLoading: state.CommonReducer.dataLoading.editCustomer,
        targetGroups: state.TargetGroupsReducer.targetGroups,
        customerConnections: state.CustomerReducer.customerConnections
    }
}


function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch),
        targetGroupsActions: bindActionCreators(TargetGroupActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditCustomerModal));
