import Card from "../../../../Components/Card/Card";
import { ActivateSubscriptionProductGroups } from "../../../../Components/Customer/VOSS/Subscriptions/ActivateSubscriptionProductGroups";
import { ActiveSubscriptionProductGroups } from "../../../../Components/Customer/VOSS/Subscriptions/ActiveSubscriptionProductGroups";
import { VossSubscriptionItemStatus } from "../../../../Constants/VOSS/vossSubscriptionItemStatus";

export function EditSubscriptionProductGroupsContainer({ ...props }) {
    const { packageLoading, productGroupsLoading, productGroupsForm, subscriptionActions, subscription } = props;

    const onActivateProductGroups = () => {
        subscriptionActions.activateSubscriptionProductGroups(productGroupsForm, () => {
            subscriptionActions.getSubscriptionProductGroups(subscription.id, VossSubscriptionItemStatus.Active), subscriptionActions.initializeSubscriptionProductGroupsForm(subscription.id);
        }
        );
    }

    return <Card>
        <Card.Header>
            <Card.Header.Title>
                Product groups
            </Card.Header.Title>
        </Card.Header>
        <Card.Content isLoading={productGroupsLoading || packageLoading || !productGroupsForm}>
            <div className="d-grid gap-24">
            <ActiveSubscriptionProductGroups {...props} />
            <ActivateSubscriptionProductGroups {...props} />
            </div>
        </Card.Content>
        <Card.Footer>
            <Card.Footer.Buttons>
                <button className="btn btn-primary active" disabled={productGroupsForm && productGroupsForm.data.productGroups <= 0} onClick={() => onActivateProductGroups()}>Activate product groups</button>
            </Card.Footer.Buttons>
        </Card.Footer>
    </Card>
}