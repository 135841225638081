import React from 'react';
import routes from '../../Constants/routes'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import helpers from "../../Utils/helpers";
import Tooltip, { TooltipContent, TooltipTrigger } from '../Overlays/Tooltip';
import infoicon from '../../Images/infoicon.svg'
import { NoInformation } from '../Panel';
import SkynetIcon from "../Fundamentals/SkynetIcon";

const VbProducts = ({ products, onButtonClick, allowCancelVbAgreement, customer, getEndDate }) => {
    const { t } = useTranslation();

    if (!products || products.length < 1)
        return <NoInformation />

    const showTerminationButton = (product) => {
        if (!product.isProductValid) {
            if (product.transferredToCustomerNumber)
                return (<p >
                    {product.terminationReason}
                    <Link className='preventRowClick' target='_top' to={routes.customerMain(product.transferredToCustomerNumber)}>{product.transferredToCustomerNumber}</Link>
                </p>);
            else
                return product.terminationReason;
        }

        if (!allowCancelVbAgreement || helpers.isAoCustomer(customer) || customer.group == 1040 || product.productNumber === 'CENTSOFT') {
            return <div>-</div>;
        }

        return <a className='preventRowClick' onClick={() => onButtonClick('terminate', product)}>{t('vbproducts.cancelagreement')}</a>;
    }

    return <>
        <div className="table-responsive">
            <table className="table table-stripe">
                <thead>
                    <tr>
                        <th width="250" className="text-left">{t('vbproducts.program')}</th>
                        <th width="100" className="text-left">{t('vbproducts.purchasedate')}</th>
                        <th width="100" className="text-left">{t('vbproducts.enddate')}</th>
                        <th width="100" className="text-left">{t('vbproducts.users')}</th>
                        <th width="100" className="text-left">{t('vbproducts.api')}</th>
                        <th width="200" className="text-left">
                            <div className="d-flex">
                                {t('vbproducts.termination')}
                                {helpers.isAoCustomer(customer) &&
                                    <Tooltip>
                                        <TooltipTrigger>
                                            {<>
                                                <img className="ml-8" src={infoicon} alt="Info" />
                                            </>}
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            {t('vbproducts.aocustomer')}
                                        </TooltipContent>
                                    </Tooltip>}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((item, index) => {
                        const purchaseDate = item.purchaseDate === '0001-01-01' ? 'N/A' : item.purchaseDate;
                        const endDate = getEndDate(item);
                        return <tr key={index} onClick={(e) => !e.target.classList.contains("preventRowClick") ? onButtonClick('productdetails', item) : null}>
                            <td className="text-left" >
                                {item.productNumber === 'TID' && !helpers.isAoCustomer(customer) ?
                                    <Tooltip options={{ placement: "right", arrowPlacement: 'right in' }}>
                                        <TooltipTrigger attributes={{ className: "max-w-max-content" }}>
                                            <>
                                                <SkynetIcon icon=" vismaicon-sm vismaicon-filled vismaicon-warning mr-8" />
                                                {item.product}
                                            </>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            {"Missa inte pågående End of life."}
                                        </TooltipContent>
                                    </Tooltip>
                                    :
                                    <>{item.product}</>
                                }
                            </td>
                            <td className="text-left">{purchaseDate}</td>
                            <td className="text-left">{!item.isProductValid ? <span style={{ color: "red" }}>{endDate}</span> : endDate}</td>
                            <td className="text-left">{item.users}</td>
                            <td className="text-left">{item.api}</td>
                            <td className="text-left">{showTerminationButton(item)}</td>
                        </tr>
                    })
                    }
                </tbody>
            </table>
        </div>
    </>
}

export default VbProducts;