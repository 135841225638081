import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../FormComponents/Input/Input";
import { Dropdown } from "../../FormComponents/Dropdown";
import Editor from "../../Editor";

export const SendExternalLeadForm = ({ ...props }) => {
    const { t } = useTranslation();
    const { customer, externalLeadForm, externalPartners, onChange, leadActions, commonActions, user } = props;
    const [selectedCompany, setSelectedCompany] = useState(null);

    useEffect(() => {
        leadActions.getExternalPartners();
        leadActions.beginExternalLeadForm(customer, user);
        return () => {
            commonActions.cancelFormEditing("externalLeadEmail");
        }
    }, []);

    const onSelectCompany = (value) => {
        let company = externalPartners.find(c => c.id === Number(value));
        leadActions.beginExternalLeadForm(customer, user, company, externalLeadForm);
        setSelectedCompany(company);
    };

    const renderFields = (fields) => {
        const parentFields = fields.filter(field => field.parentId === null);
        const onSelectOption = (field, value) => {
            let option = fields.find(option => option.id === Number(value));
            onChange('externalLeadEmail', "fields", {
                ...externalLeadForm.data.fields,
                [field.id]: option
            });
        }

        return parentFields.map(parent => {
            if (parent.type === "dropdown") {
                const options = fields
                    .filter(option => option.parentId === parent.id);

                let selectedOption = options.find(option => option.id === externalLeadForm.data.fields[parent.id]?.id);

                return <>
                    <Dropdown
                        required
                        property={'label'}
                        label={parent.label}
                        options={options}
                        value={selectedOption?.id ?? -1}
                        onChange={(value) => onSelectOption(parent, value)}
                    />
                    {selectedOption?.type === 'text' &&
                        <FormInput
                            required
                            value={externalLeadForm.data.fields[parent.id]?.value}
                            onChange={(value) => onChange('externalLeadEmail', "fields", {
                                ...externalLeadForm.data.fields, [parent.id]: { ...selectedOption, value }
                            })}
                        />
                    }
                </>
            } else if (parent.type === "text") {
                return (
                    <FormInput
                        required
                        label={parent.label}
                        value={externalLeadForm.data.fields[parent.id]?.value}
                        onChange={(value) => onChange('externalLeadEmail', "fields", {
                            ...externalLeadForm.data.fields, [parent.id]: { value }
                        })}
                    />
                );
            }
        });
    };

    let data = externalLeadForm?.data ?? {};

    return (
        <form className="skynet-form d-grid gap-16">
            <Dropdown
                required
                label={t('leadsendemailmodal.choosecompany')}
                value={selectedCompany?.id ?? -1}
                options={externalPartners}
                property="companyName"
                onChange={(value) => onSelectCompany(value)}
            />
            <fieldset className={`d-grid gap-16 mb-0`}>
                {selectedCompany && (
                    <>
                        <div>
                            <p className="bold">{t('leadsendemailmodal.companydescription')}</p>
                            <p>{selectedCompany.companyDescription}</p>
                        </div>
                        <div>
                            <p className="bold">{t('leadsendemailmodal.companyemail')}</p>
                            <p>{selectedCompany.companyEmail}</p>
                        </div>
                        {renderFields(selectedCompany.fields)}
                    </>)}
                <FormInput
                    required
                    label={t('leadsendemailmodal.customername')}
                    value={data.customerName}
                    onChange={(value) => onChange('externalLeadEmail', 'customerName', value)}
                />
                <FormInput
                    required
                    label={t('leadsendemailmodal.customernumber')}
                    value={data.customerNumber}
                    onChange={(value) => onChange('externalLeadEmail', 'customerNumber', value)}
                />
                <FormInput
                    required
                    label={t('leadsendemailmodal.phonenumber')}
                    value={data.phoneNumber}
                    onChange={(value) => onChange('externalLeadEmail', 'phoneNumber', value)}
                />
                <FormInput
                    required
                    label={t('leadsendemailmodal.contactperson')}
                    value={data.contactPerson}
                    onChange={(value) => onChange('externalLeadEmail', 'contactPerson', value)}
                />
                <FormInput
                    required
                    label={t('leadsendemailmodal.email')}
                    value={data.contactEmail}
                    onChange={(value) => onChange('externalLeadEmail', 'contactEmail', value)}
                />
                <div className="form-control skynet-form-input required">
                    <label>{t('leadsendemailmodal.description')}</label>
                    <Editor
                        type='textarea'
                        label={t('leadsendemailmodal.description')}
                        value={data.body}
                        onChange={(value) => onChange('externalLeadEmail', 'body', value)}
                    />
                </div>
            </fieldset>
        </form >
    );
};