import React, {Component, useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import actionLogActions from '../../Actions/actionLogActions'
import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom'
import routes from '../../Constants/routes'
import {DefaultCard} from "../../Components/Card/Card";

function ActionLogContainer({t, actionLogs, vbCustomer, actionLogActions, isLoading}) {

    useEffect(() => {
        actionLogActions.getCustomerActionLog(vbCustomer.customerNumber);
    }, []);


    return (
        <DefaultCard title={t('actionlogcontainer.header')}>
            {isLoading ? <div className="spinner spinner-default-green m-auto d-block"/> :
                actionLogs?.length > 0 ? <table className="table table-stripe table-actionLogs">
                    <thead>
                    <tr>
                        <th className="text-left">{t('actionlogcontainer.time')}</th>
                        <th className="text-left">{t('actionlogcontainer.action')}</th>
                        <th className="text-left">{t('actionlogcontainer.description')}</th>
                        <th className="text-left">{t('actionlogcontainer.salesperson')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {actionLogs.map((actionLog, index) => {
                        return <tr key={index}>
                            <td>{actionLog.createDate}</td>
                            {actionLog.emailActionLogId && !actionLog.hideEmailLink
                                ?
                                <td><Link to={routes.emailHistory(actionLog.emailActionLogId)}>{actionLog.action}</Link>
                                </td>
                                : <td>{actionLog.action}</td>}
                            <td>{actionLog.description}</td>
                            <td>{actionLog.salesPersonName}</td>
                        </tr>
                    })}
                    </tbody>
                </table> : <p>{t('actionlogcontainer.noentriesfound')}</p>}
        </DefaultCard>
    );
}

function mapStateToProps(state) {
    return {
        actionLogs: state.ActionLogReducer.actionLogs,
        isLoading:  state.CommonReducer.dataLoading.customerActionLogs,
        vbCustomer: state.CustomerReducer.selectedCustomer
    }
}

function mapDispatchToProps(dispatch) {
   return {
       actionLogActions : bindActionCreators(actionLogActions, dispatch) 
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ActionLogContainer));