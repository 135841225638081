import {httpFetch} from "../../Utils/httpUtils";
import {targetGroupActionTypes  as actionTypes} from "../../ActionTypes/Voss/targetGroupActionTypes";
import commonActions from "../commonActions";
import * as formValidationRules from '../../Constants/formValidationRules';
import helpers from "../../Utils/helpers";

const targetGroupActions = {

    getTargetGroups() {
        return (dispatch) => {
            return httpFetch(`/api/targetGroups/GetTargetGroups`, dispatch)
                .then(json => {
                    dispatch({type: actionTypes.STORE_TARGET_GROUPS, data: json});
                })
        }
    },

    getTargetGroup(id) {
        return (dispatch) => {
            return httpFetch(`/api/targetGroups/GetTargetGroup/${id}`, dispatch)
                .then(json => {
                    return json;
                });
        };
    },

    getTargetGroupForEdit(id) {
        if (!id) {
            let data = {
                id: null,
                name: null,
                isDefault: false,
                configuration: null,
                tags: []
            }
            return commonActions.startFormEditing("TargetGroupForm", data, formValidationRules.TargetGroup)
        }

        return (dispatch) => {
            return (this.getTargetGroup(id))
                .then(json => {
                    dispatch(commonActions.startFormEditing("TargetGroupForm", json))
                });
        }
    },

    saveTargetGroup(form, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch("/api/targetGroups/SaveTargetGroup", dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Success'));
                    dispatch(targetGroupActions.getTargetGroups());
                    if (callback) {
                        callback();
                    }
                })
        }
    },

    deleteTargetGroup(id, callback) {
        return (dispatch) => {
            return httpFetch(`/api/TargetGroups/DeleteTargetGroup/${id}`, dispatch, {method: "DELETE"})
                .then(json => {
                    dispatch(commonActions.showSuccess('Target group has been deleted'));
                    dispatch(targetGroupActions.getTargetGroups());
                    callback();
                })
        }
    },
}
export default targetGroupActions;