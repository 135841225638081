import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Card from "../../../Components/Card/Card";
import React, {useEffect, useState} from "react";
import commonActions from "../../../Actions/commonActions";
import targetGroupActions from "../../../Actions/Voss/targetGroupActions";
import {TargetGroupsFormContent} from "../../../Components/Voss/TargetGroups/TargetGroupsFormContent";
import Loader from "../../../Components/Loader";
import {useTranslation} from "react-i18next";
import routes from "../../../Constants/routes";
import {useNavigate, useSearchParams} from "react-router-dom";
import SkynetIcon from "../../../Components/Fundamentals/SkynetIcon";
import ConfirmModal from "../../Modals/ConfirmModal";

function TargetGroupsEditContainer({ targetGroupActions, commonActions, form }) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [confirmation, setConfirmation] = useState(null);

    const id = searchParams.get('Id');

    useEffect(() => {
        targetGroupActions.getTargetGroupForEdit(id);
    }, []);

    const cancelAction = () => {
        commonActions.cancelFormEditing(form.id, () => navigate(routes.vossTargetGroups));
    }

    const saveAction = () => {
        targetGroupActions.saveTargetGroup(form, () => navigate(routes.vossTargetGroups));
    }
    
    const confirm = (action, title) => {
        setConfirmation( {
            onConfirmed: action,
            confirmTitle: title
        })
        commonActions.showModal(true);
    }
    
    return <>
        <Card>
            {confirmation && <ConfirmModal {...confirmation}/>}
        <Card.Header>
            <Card.Header.Title>{`${form?.data.id ? t('targetgroupeditcontainer.edittargetgroup') : t('targetgroupeditcontainer.createtargetgroup')}`}</Card.Header.Title>
        </Card.Header>
        <Card.Content>
                    <form className="skynet-form">
                        <fieldset className="d-grid col-4 gap-16">
                            {id && <div className="alert alert-error alert-sm">
                                {t('targetgroupsformcontent.translationwarning')}
                            </div>}
                        </fieldset>
                        <TargetGroupsFormContent form={form} commonActions={commonActions}
                                                 targetGroupActions={targetGroupActions}/>
                    </form>
        </Card.Content>
        <Card.Footer>
            {form?.data.id &&
                <button type="button" className="btn btn-primary btn-destructive"
                                         onClick={() => confirm(() => targetGroupActions.deleteTargetGroup(form.data.id, () => navigate(routes.vossTargetGroups)), t('targetgroupeditcontainer.deletetargetgroup'))}>
                    {t('common.delete')}
                    <SkynetIcon icon="vismaicon vismaicon-delete" />
                </button>
            }

            <Card.Footer.Buttons>
                <>
                    <Loader/>
                    <button type="button" className="btn btn-default"
                            onClick={cancelAction}>{t('common.cancel')}</button>
                   <button type="button" className="btn btn-primary" onClick={form?.data.id ? () => confirm(saveAction,  t('targetgroupeditcontainer.savetargetgroup')) : () =>  saveAction()}>{t('common.save')}</button>
                </>
            </Card.Footer.Buttons>
        </Card.Footer>
    </Card></>
}

function mapStateToProps(state) {
    return {
        form: state.CommonReducer.forms && state.CommonReducer.forms.TargetGroupForm,
        showModal: state.CommonReducer.showModal,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        targetGroupActions: bindActionCreators(targetGroupActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetGroupsEditContainer);