export const articleTypes = {
    1: "Nyköp",
    2: "Omfakturering",
    3: "Extralicens"
}

export const articlePeriods = {
    1: "1 månad",
    3: "3 månader",
    12: "12 månader" 
}
export const productDropdown = [
    { name: "Lön Smart", id: "LonSmart" },
    { name: "Skatt & Bokslut Pro", id: "SkattBokslut" },
    { name: "eEkonomi", id: "eEkonomi" }
]