import { packageActionTypes as actionTypes } from "../../ActionTypes/Voss/packageActionTypes";
import { initialState } from "../CommonReducer";

export default function PackageReducer(state = { ...initialState }, action) {

    let newState = { ...state };

    switch (action.type) {
        case actionTypes.STORE_PACKAGES:
            newState.packages = action.data;
            break;
        case actionTypes.STORE_PACKAGE:
            newState.package = action.data;
            break;
        case actionTypes.CLEAR:
            delete newState[action.key];
            break;
        case actionTypes.STORE_PACKAGE_NAMES:
            newState.packageNames = action.data;
            break;
        case actionTypes.STORE_PRODUCT_NAMES:
            newState.productNames = action.data;
            break;
        default:
            return state;
    }

    return newState;
}
