import { bindActionCreators } from "redux";
import subscriptionActions from "../../../../Actions/Voss/subscriptionActions";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import packageActions from "../../../../Actions/Voss/packageActions";
import commonActions from "../../../../Actions/commonActions";
import { EditSubscriptionProductGroupsContainer } from "./EditSubscriptionProductGroupsContainer";
import { CardContainer } from "../../../../Components/Card/Card";
import { EditSubscriptionContainer } from "./EditSubscriptionContainer";
import { VossSubscriptionItemStatus } from "../../../../Constants/VOSS/vossSubscriptionItemStatus";
import withModalForm from "../../../../Components/HOC/withModalForm";

function CustomerSubscriptionEditContainer({ ...props }) {
  const { subscriptionActions, packageActions, commonActions, subscription, subscriptionPackage } = props;
  const [searchParams] = useSearchParams();

  const subscriptionId = searchParams.get("SubscriptionId")

  useEffect(() => {
    subscriptionActions.getSubscription(subscriptionId);
    subscriptionActions.getIntermediaries();

    return () => {
      subscriptionActions.cancelSubscriptionEditForms();
      subscriptionActions.clear("subscription");
      subscriptionActions.clear("subscriptionProductGroups");
      packageActions.clear("package");
    }
  }, [])

  useEffect(() => {
    if (subscription) {
      if (!subscriptionPackage) {
        packageActions.getPackage(subscription.package.id);
      }
      
      subscriptionActions.initializeSubscriptionEditForms(subscription);
      subscriptionActions.getSubscriptionProductGroups(subscription.id, VossSubscriptionItemStatus.Active);
    }

  }, [subscription])

  const onChange = (form, value, key) => {
    commonActions.changeMultiFormField(form, key, value);
  }

  if (!subscription || !subscriptionPackage) return null;

  return <div>
    <div className="mb-12">
      <h2 className="font-family-ubuntu-semibold mb-0">{subscription.package.name + " " + subscription.tier.name + " - " + subscription.plan.name}</h2>
    </div>
    <CardContainer>
      <EditSubscriptionContainer {...props} onChange={onChange} />
      <EditSubscriptionProductGroupsContainer {...props} onChange={onChange} />
    </CardContainer>
  </div>
}

function mapDispatchToProps(dispatch) {
  return {
    subscriptionActions: bindActionCreators(subscriptionActions, dispatch),
    packageActions: bindActionCreators(packageActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    customer: state.CustomerReducer.customer,
    subscriptionLoading: state.CommonReducer.dataLoading.subscription,
    productGroupsLoading: state.CommonReducer.dataLoading.productGroups,
    packageLoading: state.CommonReducer.dataLoading.package,
    subscription: state.SubscriptionReducer.subscription,
    intermediaries: state.SubscriptionReducer.intermediaries,
    subscriptionProductGroups: state.SubscriptionReducer.subscriptionProductGroups,
    subscriptionPackage: state.PackageReducer.package,
    subscriptionForm: state.CommonReducer.forms && state.CommonReducer.forms.modifySubscription,
    productGroupsForm: state.CommonReducer.forms && state.CommonReducer.forms.activateSubscriptionProductGroups,
  };
}


export default withModalForm((connect(mapStateToProps, mapDispatchToProps)(CustomerSubscriptionEditContainer)));