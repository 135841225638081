import { internalCustomerAdminActionTypes as actionTypes } from "../../ActionTypes/InternalCustomerAdmin/internalCustomerAdminActionTypes";
import { initialState } from "../CommonReducer";

export default function InternalCustomerAdminReducer(state = { ...initialState }, action) {

    let newState = { ...state };

    switch (action.type) {
        case actionTypes.STORE_TERMINATION_REASONS:
            newState.terminationReasons = action.data;
            break;
        case actionTypes.STORE_SERVICES:
            newState.services = action.data;
            break;
        case actionTypes.STORE_ACTIVITY_LOGS:
            newState.activityLogs = {
                data: action.data,
                type: action.logType,
            };
            break;
        case actionTypes.CLEAR:
            delete newState[action.key];
            break;
        default:
            return state;
    }

    return newState;
}
