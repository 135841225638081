import { leadActionTypes as actionTypes } from '../ActionTypes/leadActionTypes'
import { commonActionTypes } from '../ActionTypes/commonActionTypes'
import { httpFetch, httpFetchNoEncode } from '../Utils/httpUtils'

import commonActions from './commonActions'
import appointmentActions from './appointmentActions'
import actionLogActions from './actionLogActions'

import helpers from '../Utils/helpers'
import * as formValidationRules from '../Constants/formValidationRules'
import sorting from '../Utils/sorting'

const leadActions = {

    getMyLeads() {
        return (dispatch) => {
            return httpFetch('/api/leads/myLeads/', dispatch)
                .then(json => {

                    const list = sorting.sortListFromStorage("myLeads", json);
                    dispatch({ type: actionTypes.STORE_MY_LEADS, data: list });
                })
        }
    },

    getMyClosedLeads() {
        return (dispatch) => {
            return httpFetch(`/api/leads/myLeads/?closedLeads=${true}`, dispatch)
                .then(json => {

                    const list = sorting.sortListFromStorage("myLeads", json);
                    dispatch({ type: actionTypes.STORE_MY_CLOSED_LEADS, data: list });
                })
        }
    },

    getExternalPartners() {
        return (dispatch) => {
            return httpFetch('/api/leads/getExternalPartners/', dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_MY_EXTERNAL_PARTNERS, data: json });
                })
        }
    },

    getAoLeads() {
        return (dispatch) => {
            return httpFetch('/api/leads/aoLeads/', dispatch)
                .then(json => {
                    const list = sorting.sortListFromStorage("aoLeads", json);
                    dispatch({ type: actionTypes.STORE_AO_LEADS, data: list });
                })
        }
    },

    getInternalLeads() {
        return (dispatch) => {
            return httpFetch(`/api/leads/internalLeads/`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_INTERNAL_LEADS, data: json });
                })
        }
    },

    getOpenAoLeads() {
        return (dispatch) => {
            return httpFetch('/api/leads/openAoLeads/', dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_OPEN_AO_LEADS, data: json });
                })
        }
    },

    getAoSalesPersons() {
        return (dispatch) => {
            return httpFetch('/api/leads/getAoSalesPersons/', dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_AO_SALES_PERSONS, data: json });
                })
        }
    },

    getInternalEmails() {
        return (dispatch) => {
            return httpFetch('/api/leads/getInternalEmails/', dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_INTERNAL_EMAILS, data: json });
                })
        }
    },

    assignAOLead(leadId, userId, callback) {

        const httpRequest = {
            method: 'PUT'
        }

        return (dispatch) => {
            return httpFetch(`/api/leads/AssignAoLead/${leadId}/${userId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch({ type: actionTypes.UPDATE_AO_LEADS_LIST, data: json })

                    if (callback)
                        callback()
                })
        }
    },

    unassignAOLead(leadId, callback) {

        const httpRequest = {
            method: 'PUT'
        }

        return (dispatch) => {
            return httpFetch(`/api/leads/UnassignAoLead/${leadId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch({ type: actionTypes.UPDATE_AO_LEADS_LIST, data: json })

                    if (callback)
                        callback()
                })
        }
    },

    assignInternalLead(leadId, userId, callback) {

        const httpRequest = {
            method: 'PUT'
        }

        return (dispatch) => {
            return httpFetch(`/api/leads/AssignInternalLead/${leadId}/${userId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch({ type: actionTypes.UPDATE_INTERNAL_LEADS_LIST, data: json })

                    if (callback)
                        callback()
                })
        }
    },

    getAoLead(id, isRefresh, formId) {

        return (dispatch) => {

            if (isRefresh)
                dispatch(commonActions.progressLoader(true));
            else
                dispatch({ type: actionTypes.CLEAR_AO_LEAD });

            return httpFetch(`/api/leads/aoLead/${id}`, dispatch)
                .then(json => {
                    if (formId) {
                        dispatch(commonActions.startFormEditing(formId, json, formValidationRules.AoLeadForm));
                    } else {
                        dispatch({ type: actionTypes.STORE_AO_LEAD, data: json });
                    }
                })
        }
    },

    getInternalLead(id, isRefresh, formId) {

        return (dispatch) => {

            if (isRefresh)
                dispatch(commonActions.progressLoader(true));
            else
                dispatch({ type: actionTypes.CLEAR_INTERNAL_LEAD });

            return httpFetch(`/api/leads/internalLead/${id}`, dispatch)
                .then(json => {
                    if (formId) {
                        dispatch(commonActions.startFormEditing(formId, json, formValidationRules.AoLeadForm));
                    } else {
                        dispatch({ type: actionTypes.STORE_INTERNAL_LEAD, data: json });
                    }
                })
        }
    },

    assignNewLeads(form) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(form);
            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            if(form.data.leadAmount <= 0){
                dispatch(commonActions.showWarning("Lead amount is required", 'Please validate!'));
                return null;
            }

            if(form.data.leadAmount > form.data.availableLeadAmount){
                dispatch(commonActions.showWarning("Can't assign more leads than available", 'Please validate!'));
                return null;
            }

            


            var lead = { type: form.data.leadType, leadCount: form.data.leadAmount }


            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(lead)
            }

            return httpFetch(`/api/leads/assign/`, dispatch, httpRequest)
                .then(() => {
                    dispatch({ type: actionTypes.CLEAR_LEADS });
                    dispatch(commonActions.showSuccess('Lead has been assigned'));
                    dispatch(leadActions.getMyLeads());
                    dispatch(leadActions.getAvailableLeadTypes());
                })
        }

    },

    saveAo(form, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data ? form.data : form)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch('/api/leads/saveAo', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Lead has been updated'));
                    dispatch(commonActions.cancelFormEditing(form.id));
                    callback();

                    //TODO create counter for AO-leads
                    //dispatch(leadActions.getOpenLeadsCount(null));
                })
        }
    },

    saveInternal(form, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data ? form.data : form)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch('/api/leads/saveInternal', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Lead has been updated'));
                    dispatch(commonActions.cancelFormEditing(form.id));
                    callback();

                    //TODO create counter for AO-leads
                    //dispatch(leadActions.getOpenLeadsCount(null));
                })
        }
    },

    searchAoLeads(phrase) {
        return (dispatch) => {
            return httpFetch(`/api/leads/searchAoLeads/${phrase}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_AO_LEADS_SEARCH_RESULTS, data: json });
                })
        }
    },

    searchInternalLeads(phrase) {
        return (dispatch) => {
            return httpFetch(`/api/leads/searchInternalLeads/${phrase}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_INTERNAL_LEADS_SEARCH_RESULTS, data: json });
                })
        }
    },

    clearAoSearch() {
        return { type: actionTypes.CLEAR_AO_LEADS_SEARCH_RESULTS };
    },

    clearInternalSearch() {
        return { type: actionTypes.CLEAR_INTERNAL_LEADS_SEARCH_RESULTS };
    },

    reopenAo(leadId) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const httpRequest = {
                method: 'POST'
            }

            return httpFetch(`/api/leads/reopenAo/${leadId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(leadActions.getAoLead(leadId, true));
                    //TODO dispatch(leadActions.getOpenLeadsCount());
                })
        }
    },

    reopenInternal(leadId) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const httpRequest = {
                method: 'POST'
            }

            return httpFetch(`/api/leads/reopenInternal/${leadId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(leadActions.getInternalLead(leadId, true));
                    //TODO dispatch(leadActions.getOpenLeadsCount());
                })
        }
    },

    getMyLeadsForCustomer(customerNumber) {
        return (dispatch) => {
            return httpFetch(`/api/leads/myCustomerLeads/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_MY_CUSTOMER_LEADS, data: json });
                })
        }
    },

    getLeads(type) {
        return (dispatch) => {
            return httpFetch(`/api/leads/unassigned/${type}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_LEADS, data: json });
                })
        }
    },

    getLead(id, isRefresh, isSelected, formId) {
        return (dispatch) => {

            if (!id)
                return dispatch({ type: actionTypes.CLEAR_LEAD });

            if (isRefresh) {
                dispatch(commonActions.progressLoader(true));
            } else {
                dispatch({ type: actionTypes.CLEAR_LEAD });
            }

            dispatch(leadActions.clearTrials);
            return httpFetch(`/api/leads/lead/${id}`, dispatch)
                .then(json => {
                    if (formId) {
                        dispatch(commonActions.startFormEditing(formId, json, formValidationRules.LeadForm));
                    } else {
                        dispatch({ type: actionTypes.STORE_LEAD, data: json });
                        dispatch(commonActions.loadDropdownList("LeadsCloseReasonCodes,LeadsBuyReasonCodes"))
                    }
                    if (isSelected) {
                        dispatch({ type: commonActionTypes.STORE_SELECTED, data: { customerNumber: json.customerNumber, type: "lead", typeId: json.id } });
                    }
                    if (json.email || json.email.trim() !== '')
                        dispatch(leadActions.getTrials(json.email))
                })
        }
    },

    getLeadsCloseReasonCodes() {
        return (dispatch) => {
            dispatch(commonActions.loadDropdownList("LeadsCloseReasonCodes,LeadsBuyReasonCodes"))
        }
    },

    getAvailableLeadTypes() {
        return (dispatch) => {
            return httpFetch('/api/leads/types/', dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_LEAD_TYPES, data: json });
                })
        }
    },

    save(form, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data ? form.data : form)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch('/api/leads/save', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Lead has been updated'));
                    dispatch(commonActions.cancelFormEditing(form.id));
                    callback();

                    dispatch(leadActions.getOpenLeadsCount(null));
                })
        }
    },

    unassignLead(form) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data ? form.data : form)
            }

            dispatch(commonActions.progressLoader(true))

            return httpFetch('/api/leads/save', dispatch, httpRequest)
                .then(json => {
                    dispatch({ type: actionTypes.CLEAR_LEADS });
                    dispatch({ type: actionTypes.STORE_LEAD, data: null });
                    dispatch(commonActions.showSuccess('Lead has been unassigned'));
                    dispatch(leadActions.getMyLeads());
                    dispatch(leadActions.getAvailableLeadTypes());
                })
        }
    },

    clearLead() {
        return (dispatch) => {
            dispatch({ type: actionTypes.CLEAR_LEADS });
            dispatch({ type: actionTypes.STORE_LEAD, data: null });
            dispatch(leadActions.getMyLeads());
            dispatch(leadActions.getAvailableLeadTypes());
        }
    },


    saveAction(leadId, form, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch(`/api/leads/SaveAction/${leadId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Saved to action history'));
                    if (callback) {
                        callback();
                    }
                })
        }
    },

    saveInternalAction(leadId, form, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch(`/api/leads/SaveInternalAction/${leadId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Saved to action history'));
                    if (callback) {
                        callback();
                    }
                })
        }
    },

    sendEmail(leadId, emailForm, callback, leadType) {

        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(emailForm);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(emailForm.data)
            }

            dispatch(commonActions.progressLoader(true))

            let action = leadType === "aoLead" ? "SendEmailAO" : "SendEmail";

            return httpFetch(`/api/leads/${action}/${leadId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Email has been sent'));

                    callback();
                })
        }
    },

    startAppointmentModal(data) {
        return (dispatch) => {
            dispatch(commonActions.startFormEditing("leadAppointment", data, formValidationRules.AppointmentForm))
        }
    },

    startPhoneModal(data) {
        return (dispatch) => {
            dispatch(commonActions.startFormEditing("leadPhoneModal", data, formValidationRules.LeadPhoneModal))
        }
    },

    startAddInfoModal(data) {
        return (dispatch) => {
            dispatch(commonActions.startFormEditing("leadAddInfoModal", data, formValidationRules.LeadAddInfoModal))
        }
    },

    startCloseModal(data) {
        return (dispatch) => {
            dispatch(commonActions.startFormEditing("leadCloseModal", data, formValidationRules.LeadPhoneModal))
        }
    },

    startNewLeadsModal(data) {
        return (dispatch) => {
            dispatch(commonActions.startFormEditing("newLeadsModal", data, formValidationRules.NewLeadsForm))
        }
    },

    startBuyModal(data) {
        return (dispatch) => {
            dispatch(commonActions.startFormEditing("leadBuyModal", data, formValidationRules.LeadPhoneModal))
        }
    },

    startBuyModalAo(data) {
        return (dispatch) => {
            dispatch(commonActions.startFormEditing("leadBuyModalAo", data, formValidationRules.AoLeadBuyForm))
        }
    },

    getEmailTemplate(id, customerEmail, customerNumber) {
        return (dispatch) => {
            return httpFetch(`/api/leads/emailTemplate/${id}`, dispatch)
                .then(json => {
                    json.to = customerEmail
                    json.customerNumber = customerNumber
                    json.emailTemplate = json.name
                    json.defaultFrom = json.from

                    dispatch(commonActions.startFormEditing("leadEmail", json, formValidationRules.EmailTemplate))
                })
        }
    },

    getFormFromEmailActionLog(emailActionLogId) {
        return dispatch => {
            dispatch(commonActions.cancelFormEditing("leadEmail"))
            dispatch(actionLogActions.getFormFromEmailActionLog(emailActionLogId, json =>
                dispatch(commonActions.startFormEditing('leadEmail', json, formValidationRules.EmailTemplate))))
        }
    },

    startEmailSendingModal(customerEmail, customerNumber) {
        return (dispatch) => {
            dispatch(commonActions.loadDropdownList("LeadsEmailTemplates"))
            dispatch(leadActions.getEmailTemplate(null, customerEmail, customerNumber))
        }
    },

    beginInternalLeadForm(customer, lead){
        return (dispatch) => {
            dispatch(commonActions.startFormEditing("internalLeadEmail", {
                customerNumber: customer.customerNumber,
                phonumber: customer.contactPerson.phoneNumber,
                contactPerson: customer.contactPerson.firstName + ' ' + customer.contactPerson.lastName,
                contactEmail: customer.contactPerson.email,
                leadType: lead.id,
                isInternal: lead.isInternal,
                body: '',
            }, formValidationRules.InternalLead));
        }  
    },

    beginExternalLeadForm(customer, user, company, externalLeadForm) {
        return (dispatch) => {
            let validationRules = formValidationRules.ExternalLead;
    
            const fields = company?.fields || [];
            const parentFields = fields.filter(field => field.parentId === null);
    
            let formPayload = externalLeadForm ? {
                ...externalLeadForm.data,
                partnerId: company.id,
                fields: parentFields.reduce((acc, field) => {
                    acc[field.id] = field;
                    return acc;
                }, {})
            } : {
                customerName: customer.name,
                customerNumber: customer.customerNumber,
                phoneNumber: customer.contactPerson.phoneNumber,
                contactPerson: customer.contactPerson.firstName + ' ' + customer.contactPerson.lastName,
                contactEmail: customer.contactPerson.email,
                body: '',
                salesPerson: user.name,
                partnerId: company?.id,
                fields: parentFields.reduce((acc, field) => {
                    acc[field.id] = field;
                    return acc;
                }, {})
            };
    
            validationRules = validationRules.concat(parentFields.map(field => {
                return {
                    inputId: field.id,
                    rule: 'required',
                    message: `"${field.label}" is required`
                };
            }));
    
            dispatch(commonActions.startFormEditing("externalLeadEmail", formPayload, validationRules));
        };
    },


    saveAppointment(form, leadId, leadType, callback, createBooking) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            dispatch(commonActions.progressLoader(true))

            let action = leadType === "aoLead" ? "appointmentAo" : leadType === "internalLead" ? "appointmentInternal" : "appointment";

            return httpFetch(`/api/leads/${action}/${leadId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(appointmentActions.getOpenAppointmentsCount(null));
                    dispatch(leadActions.getOpenLeadsCount(null));
                    dispatch(commonActions.showSuccess('Appointment has been created'));
                    dispatch(commonActions.cancelFormEditing(form.id));
                    if (createBooking) {
                        dispatch(appointmentActions.redirectNewCreationToBooking());
                    }

                    callback();
                })
        }
    },

    close(leadId, form, leadType, callback) {

        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            let action = leadType === "aoLead" ? "closeAo" : leadType === "internalLead" ? "closeInternal" : "close";

            return httpFetch(`/api/leads/${action}/${leadId}/${form.data.amount}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Success'));
                    dispatch(leadActions.getOpenLeadsCount(null));

                    if (callback) {
                        callback();
                    }
                })
        }
    },

    closeMultiple(leads, form, callback) {

        return dispatch => {

            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const data = {
                reason: form.data,
                leads: leads
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(data)
            }

            return httpFetch(`/api/leads/closemultiple/`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Success'));

                    dispatch(leadActions.getOpenLeadsCount());
                    dispatch(leadActions.getMyLeads());
                    dispatch(leadActions.clearSelectedLeads());

                    callback && callback();
                })
        }
    },

    reopen(leadId) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const httpRequest = {
                method: 'POST'
            }

            return httpFetch(`/api/leads/reopen/${leadId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(leadActions.getLead(leadId, false, true));
                    dispatch(leadActions.getOpenLeadsCount());
                })
        }
    },

    getCustomerLeads(customerNumber) {
        return (dispatch) => {
            return httpFetch(`/api/leads/customer/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_CUSTOMER_LEADS, data: json });
                })
        }
    },

    getAoCustomerLeads(customerNumber) {
        return (dispatch) => {
            return httpFetch(`/api/leads/AoCustomer/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_AO_CUSTOMER_LEADS, data: json });
                })
        }
    },

    getOpenLeadsCount() {
        return dispatch => {
            return httpFetch(`/api/leads/openCount`, dispatch)
                .then(json => {
                    dispatch(leadActions.updateLeadsCount(json));
                })
        }
    },

    updateLeadsCount(count) {
        return {
            type: actionTypes.UPDATE_LEADS_COUNT,
            openLeadsCount: count
        }
    },

    getMySignature() {
        return dispatch => {
            return httpFetch(`/api/leads/mySignature`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing("leadsMySignature", json, null))
                })
        }
    },

    updateMySignature(form, callback) {
        return dispatch => {
            dispatch(commonActions.progressLoader(true));

            var regex = /(<([^>]+)>)/ig
            form.data.noHtml = form.data && form.data.signature && form.data.signature.replace(regex, '');

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch(`/api/leads/updateMySignature/`, dispatch, httpRequest)
                .then(() => {
                    dispatch(commonActions.showSuccess('Signature has been updated'));
                    callback();
                })
        }
    },

    getMyTemplate(id) {
        return dispatch => {
            return httpFetch(`/api/leads/myEmailTemplate/${id}`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing("leadsMyTemplate", json, null));
                })
        }
    },

    saveMyTemplate(form, callback) {
        return dispatch => {
            dispatch(commonActions.progressLoader(true));

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch(`/api/leads/saveMyTemplate/`, dispatch, httpRequest)
                .then(() => {
                    dispatch(commonActions.showSuccess(`Template has been ${form.data.templateId > 0 ? 'updated' : 'created'}`));
                    callback();
                })
        }
    },

    deleteMyTemplate(id, callback) {
        const httpRequest = { method: 'DELETE' }

        return dispatch => {
            return httpFetch(`/api/leads/deleteMyTemplate/${id}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess(`Template has been deleted`));
                    callback();
                })
        }
    },

    assingLeads(type, leadCount) {

        var lead = { type: type, leadCount: leadCount }

        return dispatch => {
            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(lead)
            }

            return httpFetch(`/api/leads/assign/`, dispatch, httpRequest)
                .then(() => {
                    dispatch({ type: actionTypes.CLEAR_LEADS });
                    dispatch(commonActions.showSuccess('Lead has been assigned'));
                    dispatch(leadActions.getMyLeads());
                    dispatch(leadActions.getAvailableLeadTypes());
                })
        }
    },

    searchMyLeads(phrase, closed) {
        return (dispatch) => {
            return httpFetchNoEncode(`/api/leads/SearchMyLeads/${encodeURIComponent(phrase)}?closed=${closed}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_SEARCH_MY_LEADS_RESULTS, data: json });
                })
        }
    },

    clearSearch() {
        return { type: actionTypes.CLEAR_SEARCH_RESULTS };
    },

    getTrials(email) {

        return (dispatch) => {
            dispatch(commonActions.progressLoader(true));
            return httpFetchNoEncode('/api/leads/SearchTrials/' + encodeURIComponent(email), dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.CUSTOMER_TRIALS, data: json });
                    dispatch(commonActions.progressLoader(false));
                })
        }
    },

    clearTrials() {
        return (dispatch) => {
            dispatch({ type: actionTypes.CUSTOMER_TRIALS, data: null });
        }
    },

    searchCustomersByEmail(phrase) {

        return (dispatch) => {

            dispatch(commonActions.progressLoader(true));

            if (!phrase || phrase.trim() === '') {
                dispatch(leadActions.clearCustomersByEmail());
                dispatch(commonActions.progressLoader(false));
                return;
            }

            return httpFetchNoEncode('/api/leads/SearchCustomers/' + encodeURIComponent(phrase), dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_CUSTOMERS_FOR_LEAD_EMAIL, data: json });
                    dispatch(commonActions.progressLoader(false));
                })
        }

    },

    clearCustomersByEmail() {
        return (dispatch) => {
            dispatch({ type: actionTypes.STORE_CUSTOMERS_FOR_LEAD_EMAIL, data: { result: [] } })
        }

    },

    clearSelectedLeads() {
        return { type: actionTypes.CLEAR_SELECTED_LEADS };
    },

    selectLead(lead, selectedLeads) {
        
        if (!selectedLeads)
            selectedLeads = [];

        const result = selectedLeads.filter(item => item.id === lead.id)

        if (result.length === 0) {
            selectedLeads.push(lead);
        }
        else {
            selectedLeads = selectedLeads.filter(item => item.id !== lead.id)
        }

        return {
            type: actionTypes.SELECTED_LEADS, data: selectedLeads
        }
    },
}

export default leadActions;