import SkynetIcon from "../../Fundamentals/SkynetIcon";
import React from "react";
import {useTranslation} from "react-i18next";

export function DynamicInputTable({inputHeadRows, headRows, inputRow, current, currentRow, onAdd, onDelete, currentTitle, title, disableAdd}) {
    const {t} = useTranslation();

    return <div>
        {title && <legend>{title}</legend>}
        <table className="table">
            <thead>
                <tr>
                    {inputHeadRows ?? headRows}
                </tr>
            </thead>
            <tbody>
                <tr>
                    {inputRow}
                </tr>
            </tbody>
        </table>
        <div className="float-right">
            <button type="button" className="btn btn-primary" disabled={disableAdd} onClick={onAdd}>{t('common.add')}</button>
        </div>
        <table className="table table-stripe">
            <caption>{currentTitle ?? "Current"}</caption>
            <thead>
                <tr>
                    {headRows}
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {current?.map((item, index) => (
                <tr key={index}>
                    {currentRow(item)}
                    <td>
                        <div className="float-right ">
                            <SkynetIcon icon={'vismaicon-delete pr-4 vismaicon-sm'}
                                        title={t('common.delete')}
                                        onClick={() => onDelete(index)}/>
                        </div>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    </div>
}