import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import commonActions from "../../../Actions/commonActions";
import customerActions from "../../../Actions/customerActions";
import Modal from "../../Modal";
import React, {useEffect, useState} from "react";
import SkynetIcon from "../../Fundamentals/SkynetIcon";
import {Dropdown} from "../../FormComponents/Dropdown";
import CustomerSearchNew from "../../Search/CustomerSearchNew";
import FormSearch from "../../Search/FormSearch";
import {CustomerConnectionType} from "../CustomerComponents/CustomerConnections";
import {productDropdown} from "../../../Constants/dropDownLists";
import {DynamicInputTable} from "../../FormComponents/Input/DynamicInputTable";

function CustomerConnectionsEditModal ({customer, customerActions, commonActions, form, customerConnections, onClose, t, saving }) {
    const [selectedProductId, setSelectedProductId] = useState();
    const [selectedType, setSelectedType] = useState();
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [current, setCurrent] = useState(customerConnections);
    const [clearSearch, setClearSearch] = useState(null);
    let formData = form?.data;

    useEffect(() => {
        commonActions.startFormEditing("customerConnectionsForm", {toAdd: [], toDelete: []});
    }, [])

    const onChange = (value, key) => {
        commonActions.changeMultiFormField(form.id, key, value);
    }

    const onDelete = (index) => {
        const customer = current[index];
        const updatedCurrent = current.filter((_, i) => i !== index);
        const updatedToAdd = formData.toAdd.filter(c => c.customerNumber !== customer.customerNumber && c.product !== customer.product && c.type !== customer.type);

        setCurrent(updatedCurrent);
        if (updatedToAdd.length < formData.toAdd.length) {
            onChange([...updatedToAdd], "toAdd");
        } else {
            onChange([...formData.toDelete, customer], "toDelete");
        }

    }
    const onAdd = () => {
        if (!selectedType || !selectedProductId || !selectedCustomer.customerNumber) return;

        let typeAsNumber = Number(selectedType);
        let newConnectedCustomer = {
            id: customerConnections.find(c => c.customerNumber === selectedCustomer.customerNumber && c.product === selectedProductId && c.type === typeAsNumber)?.id,
            type: typeAsNumber,
            customerNumber: selectedCustomer.customerNumber,
            name: selectedCustomer.name,
            organizationNumber: selectedCustomer.organizationNumber,
            product: selectedProductId
        }

        setCurrent([...current, newConnectedCustomer]);

        const updatedToDelete = formData.toDelete.filter((c => c.customerNumber !== newConnectedCustomer.customerNumber && c.type !== newConnectedCustomer.type && (c.product === "eEkonomi" || c.product !== newConnectedCustomer.product)));

        if (updatedToDelete.length === formData.toDelete.length) {
            onChange([...formData.toAdd, newConnectedCustomer], "toAdd");
        } else if (updatedToDelete.length < formData.toDelete.length) {
            onChange([...updatedToDelete ?? []], "toDelete");
        }

        setSelectedProductId(null);
        setSelectedType(null);
        setSelectedCustomer(null);
        commonActions.clearCustomerSearch();
        setClearSearch(!clearSearch);
    }

    const handleClickSave = () => {
        customerActions.saveCustomerConnections(form, customer.vbCustomer?.customerNumber ?? customer.vossCustomer?.customerNumber, customer.vbCustomer?.id ?? customer.vossCustomer?.id, onClose);
    }

    function onCancel() {
        commonActions.cancelFormEditing(form.id, onClose);
    }

    const modalProps = {
        title: t('customerconnections.editcustomerconnections'),
        mainButtonTitle: t('common.save'),
        onCancelClick: onCancel,
        stateful: true,
        open: true,
        onSaveClick: handleClickSave,
        customClassName: "modal-xl",
        showButtons: !saving,
        disableMainButton: formData?.toAdd.length === 0 && formData?.toDelete.length === 0
    }

    const headRows = <>
        <th>{t('customerconnections.type')}</th>
        <th>{t('customerconnections.customer')}
            <small>({t('common.name')} / {t('customerconnections.custno')} / {t('customerconnections.orgno')})</small>
        </th>
        <th>{t('customerconnections.product')}</th>
    </>

    const inputRow = <>
        <td className="p-12">
            <Dropdown options={[{name: "Huvudkund", id: 0}, {name: "Kopplad kund", id: 1}]}
                      valueProperty={"id"}
                      property={"name"} placeholder={t('customerconnections.selecttype')}
                      value={selectedType}
                      onChange={(value) => setSelectedType(value)}/>
        </td>
        <td className="p-12">
            <FormSearch>{(className) =>
                <CustomerSearchNew onChange={(customerNumber, customerName, organizationNumber) => setSelectedCustomer({
                    customerNumber: customerNumber,
                    name: customerName,
                    organizationNumber: organizationNumber,
                })}
                                   className={className + "pb-0"}
                                   clearSearch={clearSearch}/>}
            </FormSearch>
        </td>
        <td className="p-12">
            <Dropdown options={productDropdown} valueProperty={"id"}
                      property={"name"} placeholder={t('customerconnections.selectproduct')}
                      value={selectedProductId}
                      onChange={(value) => setSelectedProductId(value)}/>
        </td>
    </>

    function currentRow(customer) {
        return <>
            <th><CustomerConnectionType customer={customer}/></th>
            <td>{customer.name} / {customer.customerNumber} / {customer.organizationNumber}</td>
            <td>{productDropdown.find(p => p.id === customer.product).name ?? ""}</td>
        </>
    }


    return (
        <Modal {...modalProps}>
            {!form || saving ? <div className="spinner spinner-default-green m-auto d-block"/> :
                <form className="skynet-form container">
                    <fieldset>
                        {t('customerconnections.addandremove')}
                    </fieldset>
                    <fieldset className="d-grid col-12 gap-16">
                        <DynamicInputTable headRows={headRows} currentRow={currentRow} inputRow={inputRow}
                                           current={current} onAdd={onAdd}
                                           disableAdd={!selectedType || !selectedProductId || !selectedCustomer.customerNumber}
                                           onDelete={onDelete} currentTitle={"Current customer connections"}/>
                    </fieldset>
                </form>}
        </Modal>
    );
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.customer,
        customerConnections: state.CustomerReducer.customerConnections,
        form: state.CommonReducer.forms && state.CommonReducer.forms.customerConnectionsForm,
        saving: state.CommonReducer.dataLoading.saveCustomerConnections
    }
}


function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerConnectionsEditModal));
