import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTranslation, withTranslation} from 'react-i18next';
import settingsActions from '../../../Actions/settingsActions'
import commonActions from '../../../Actions/commonActions'
import packageActions from '../../../Actions/Voss/packageActions'
import targetGroupActions from "../../../Actions/Voss/targetGroupActions";
import { hasAdminAccess } from '../../../Utils/permissionUtils';
import { UserRoles } from '../../../Constants/userRoles';
import Card from '../../../Components/Card/Card';
import helpers from '../../../Utils/helpers';
import Grid from "../../../Components/Grid/Grid";
import { Checkbox } from "../../../Components/FormComponents/Checkbox";
import { RadioButtons } from "../../../Components/FormComponents/RadioButtons";
import DateTimeInput from "../../../Components/DateTimeInput";
import SkynetIcon from "../../../Components/Fundamentals/SkynetIcon";
import {PackageSelector} from "../../../Components/FormComponents/PackageSelector";
import {FeatureFlags, isFeatureEnabled} from "../../../Utils/featureFlags";
import ConfirmModal from "../../Modals/ConfirmModal";

function InvoicingContainer({ settingsActions, user, parameters, invoiceProcessings, targetGroupActions, targetGroups, dataLoading, commonActions, packageActions, packages, products}) {

    useEffect(() => {
        if (!hasAdminAccess(user, UserRoles.Invoicing))
            return;
        settingsActions.getInvoiceProcessingSettings();
        if (!invoiceProcessings)
            settingsActions.getInvoiceProcessings();
        if (!targetGroups)
            targetGroupActions.getTargetGroups();
        if (!packages)
            packageActions.getPackageNames();
        if (!products)
            packageActions.getProductNames();
    }, [])

    const {t} = useTranslation();

    const [customerNumber, setCustomerNumber] = useState([]);
    const [confirmation, setConfirmation] = useState(null);

    const createInvoicing = () => {
        if (!parameters.typeDebit && !parameters.typeCredit)
            commonActions.showWarning(t('invoiceprocessing.choosetype'));
        else if (!parameters.sourceNewPurchase && !parameters.sourceRenewal && !parameters.sourceUsage)
            commonActions.showWarning(t('invoiceprocessing.choosesource'));
        else if (parameters.showCustomerNumbers && parameters.customerNumbers.length === 0)
            commonActions.showWarning(t('invoiceprocessing.nocustomerselected'));
        else if (!parameters.showCustomerNumbers && !(parameters.onlyAO))
        {
            setConfirmation({
                onConfirmed: onCreateInvoicing,
                confirmTitle: t("invoiceprocessing.confirmcreateinvoicing"),
                confirmTextElement: <p>{t("invoiceprocessing.createforallcustomers")}<br/>{t("invoiceprocessing.continue")}</p>
            })
            commonActions.showModal(true);
        }
        else
            onCreateInvoicing();
    }

    const onCreateInvoicing = () => {
        settingsActions.createInvoiceProcessing(parameters,  targetGroups, () => setTimeout(refreshInvoiceProcessings, 3000))
    }

    const refreshInvoiceProcessings = () => {
        settingsActions.storeInvoiceProcessings(null)
        settingsActions.getInvoiceProcessings();
    }

    const deleteInvoiceProcessing = (id) => {
        setConfirmation({
            onConfirmed: () => onDeleteInvoicing(id),
            confirmTitle: t("invoiceprocessing.confirmdeleteinvoicing"),
            confirmTextElement: <p>{t("invoiceprocessing.deletewarning")}</p>
        });
        commonActions.showModal(true);
    }

    const onDeleteInvoicing = (id) => {
        settingsActions.deleteInvoiceProcessing(id, () => {
            settingsActions.storeInvoiceProcessings(invoiceProcessings.filter(i => i.id !== id));
        });
    }

    const downloadInvoiceFile = (id) => {
        settingsActions.downloadInvoiceFile(id, `${id}.txt`);
    }

    const timeItems = [
        {label: t('invoiceprocessing.untilnow'), value: 'time'},
        {label: t('invoiceprocessing.date'), value: 'date'}
    ]

    const selectTimeOrDate = (value) => {
        if (value === 'date' && !parameters.invoiceDate)
            parameters.invoiceDate = helpers.getCurrentDate()
        settingsActions.storeInvoiceProcessingParameters({timeOrDate: value, invoiceDate: parameters.invoiceDate});
    }

    const changeInvoiceDate = (date) => {
        if (isFeatureEnabled(user, FeatureFlags.VSCO_2308_Future_dates) || new Date(date) <= new Date())
            settingsActions.storeInvoiceProcessingParameters({invoiceDate: date});
    }

    const toggleCheckbox = (checked, id) => {
        settingsActions.storeInvoiceProcessingParameters({[id]: checked});
    }

    const addCustomerNumber = () => {
        parameters.customerNumbers.push(customerNumber);
        settingsActions.storeInvoiceProcessingParameters({customerNumbers: parameters.customerNumbers});
        setCustomerNumber("")
    }

    const clearCustomerNumbers = () => {
        settingsActions.storeInvoiceProcessingParameters({customerNumbers: []});
    }

    const removeCustomerNumber = (custNo) => {
        parameters.customerNumbers = parameters.customerNumbers.filter(cn => cn !== custNo);
        settingsActions.storeInvoiceProcessingParameters({customerNumbers: parameters.customerNumbers});
    }

    const selectPackage = (packageId) => {
        settingsActions.storeInvoiceProcessingParameters({packageId: packageId});
    }

    const selectProduct = (productId) => {
        settingsActions.storeInvoiceProcessingParameters({productId: productId});
    }

    const prefill = (type) => {
        let param = { showCustomerNumbers: false };
        param.tranSourceVoss = param.tranSourceVon = true
        param.productId = param.packageId = null;
        param.showProducts = param.showPackages = false;
        param.onlyAO = param.typeCredit = param.sourceNewPurchase = param.typeDebit = param.sourceUsage = param.sourceRenewal = param.normalCustomers = false;
        if (type === 'credit_ao') {
            param.onlyAO = param.typeCredit = param.sourceNewPurchase = param.sourceRenewal = true;
        }
        else if (type === 'debit') {
            param.typeDebit = param.sourceNewPurchase = param.normalCustomers = true;
        }
        else if (type === 'credit') {
            param.typeCredit = param.sourceNewPurchase = param.sourceRenewal = param.normalCustomers =true;
        }
        // else if (type === 'online_ao') {
        //     param.typeCredit = false;
        //     param.onlyAO = param.typeDebit = param.sourceRenewal = param.sourceUsage = param.sourceNewPurchase = true;
        // }
        else if (type === 'online') {
            param.typeDebit = param.sourceRenewal = param.sourceUsage = true;
        }
        else if (type === 'koncern') {
            param.typeDebit = param.typeCredit = param.sourceNewPurchase = param.sourceRenewal = true;
            const koncern = packages.find(p => p.name === 'Visma Koncern');
            param.packageId = koncern.id;
            const koncernLicense = products.find(p => p.articleNumber === 'SEVO.CON.YR.SUB');
            param.productId = koncernLicense.id;
            param.tranSourceVoss = param.showProducts = param.showPackages = true;
            param.tranSourceVon = false;
        }

        settingsActions.storeInvoiceProcessingParameters(param);
    }

    const renderCustomerNumbers = () => {
        if (parameters.showCustomerNumbers)
            return <>
                <div className="d-flex flex-row gap-10" style={{marginLeft: 20}}>
                    <input className="form-control rounded-8 skynet-form-input" style={{width: 80}}
                           onChange={a => setCustomerNumber(a.target.value)}
                           value={customerNumber}
                           onKeyDown={e => {
                               if (e.key === "Enter") addCustomerNumber()
                           }}/>

                    <button className="btn btn-default" onClick={addCustomerNumber}>
                        {t("invoiceprocessing.add")}
                    </button>
                    <button className="btn btn-default" onClick={clearCustomerNumbers}>
                        {t("invoiceprocessing.clear")}
                    </button>
                </div>
                <table className="table table-hover" style={{marginLeft: 20}}>
                    <tbody>
                    {p.customerNumbers.map((custNo, index) => {
                        return <tr key={`${index}${custNo}`}>
                        <td>{custNo}</td>
                            <td>
                                <button className="btn btn-default" onClick={() => removeCustomerNumber(custNo)}>
                                    {t("invoiceprocessing.remove")}
                                </button>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </>
        return <></>
    }
    const featTS = isFeatureEnabled(user, FeatureFlags.VOSS_2308_Invoicing_TransactionSource)

    const renderInvoiceTable = (invoiceProcessings) => {
        if (!invoiceProcessings)
            return <div className="spinner spinner-default-green m-auto d-block"/>;
        return <div className="table-responsive">
            <table className="table table-hover">
                <thead>
                <tr>
                    <th>{t("invoiceprocessing.invoicedate")}</th>
                    {featTS ? <th>System</th> : null}
                    <th>{t("invoiceprocessing.totalsum")}</th>
                    <th>{t("invoiceprocessing.type")}</th>
                    <th>{t("invoiceprocessing.source")}</th>
                    <th>{t("invoiceprocessing.selection")}</th>
                    <th>{t("invoiceprocessing.package")}</th>
                    <th>{t("invoiceprocessing.regret")}</th>
                    <th>{t("invoiceprocessing.downloadfile")}</th>
                </tr>
                </thead>
                <tbody>
                {
                    invoiceProcessings.map((invoiceProcessing, index) => {
                        const filters = invoiceProcessing.filters;
                        const custNo = []
                        if (targetGroups) {
                            const normalGroup = targetGroups.find(g => g.name === "Normal").id
                            const accountingOfficeGroup = targetGroups.find(g => g.name === "AccountingOffice").id

                            if (filters.invoiceRecipientTargetGroupIds.some(id => id === normalGroup))
                                custNo.push(t("invoiceprocessing.normalcustomers"));
                            if (filters.invoiceRecipientTargetGroupIds.some(id => id === accountingOfficeGroup))
                                custNo.push(t("invoiceprocessing.accountingoffice"));
                        }
                        if (filters.customerNumbers.length) custNo.push(filters.customerNumbers.join(", "));
                        return <tr key={index}>
                                <td>{helpers.formatDateAndTime(invoiceProcessing.invoiceDate)}</td>
                                {featTS ? <td>{filters.transactionSources.join(", ")}</td> : null}
                                <td>{invoiceProcessing.totalAmount}</td>
                                <td>{filters.transactionTypes.map(v => t(`invoiceprocessing.${v}`)).join(", ")}</td>
                                <td>{filters.transactionSourceTypes.map(v => t(`invoiceprocessing.${v}`)).join(", ")}</td>
                                <td>{custNo.length ? custNo.join(", ") : t("invoiceprocessing.allcustomers")}</td>
                                <td>{filters.packageIds.map(id => {
                                        const p = packages?.find(p => p.id === id);
                                        return p ? p.name : "Loading";
                                    })}
                                </td>
                                <td>
                                    <SkynetIcon icon="vismaicon-delete" onClick={() => deleteInvoiceProcessing(invoiceProcessing.id)}/>
                                </td>
                                <td>
                                    {
                                        dataLoading && dataLoading[`download_${invoiceProcessing.id}`] === true ?
                                            <div style={{maxWidth: 25, maxHeight: 25}} className="spinner spinner-default-green m-auto"/> :
                                            <SkynetIcon icon="vismaicon-download"
                                                        onClick={() => downloadInvoiceFile(invoiceProcessing.id)}/>
                                    }
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
        }

        const p = parameters;

        return <Grid>
            <Grid.Row>
                <Grid.Row.Column width={3}>
                    <Card>
                        <Card.Header>
                            <Card.Header.Title>{t("invoiceprocessing.createinvoicing")}</Card.Header.Title>
                        </Card.Header>
                        <Card.Content>

                            <fieldset className="d-grid col-6 gap-8">
                                <RadioButtons items={timeItems} label={<b>{t("invoiceprocessing.invoicedate")}</b>}
                                              onChange={selectTimeOrDate} value={p.timeOrDate}/>
                                {p.timeOrDate === 'date' ?
                                    <div style={{width: 300, marginTop: -20}}>
                                        <DateTimeInput showTime={false} closeOnSelect={true} label=""
                                                       onChange={changeInvoiceDate} value={p.invoiceDate}/>
                                    </div>
                                    : <></>}
                            </fieldset>
                            <fieldset className="d-grid col gap-8">
                                <label><b>{t("invoiceprocessing.preselections")}</b></label>
                                <div>
                                    <button className="btn btn-default" onClick={() => prefill('credit_ao')}>{t("invoiceprocessing.creditao")}</button>
                                    <button className="btn btn-default" onClick={() => prefill('debit')}>{t("invoiceprocessing.Debit")}</button>
                                    <button className="btn btn-default" onClick={() => prefill('credit')}>{t("invoiceprocessing.Credit")}</button>
                                    {/*<button className="btn btn-default" onClick={() => prefill('online_ao')}>{t("invoiceprocessing.online_ao")}</button>*/}
                                    <button className="btn btn-default" onClick={() => prefill('online')}>{t("invoiceprocessing.online")}</button>
                                    <button className="btn btn-default" onClick={() => prefill('koncern')}>{t("invoiceprocessing.koncern")}</button>
                                </div>
                            </fieldset>

                            <div className="d-flex flex-row">
                            <fieldset className="d-grid col-6 gap-8">
                                    <label><b>{t("invoiceprocessing.type")}</b></label>
                                    <Checkbox id="typeDebit" onChange={toggleCheckbox}
                                              label={t("invoiceprocessing.Debit")} checked={p.typeDebit}/>
                                    <Checkbox id="typeCredit" onChange={toggleCheckbox}
                                              label={t("invoiceprocessing.Credit")} checked={p.typeCredit}/>
                                </fieldset>
                                <fieldset className="d-grid col-6 gap-8">
                                    <label><b>{t("invoiceprocessing.source")}</b></label>
                                    <Checkbox id="sourceNewPurchase" onChange={toggleCheckbox}
                                              label={t("invoiceprocessing.NewPurchase")} checked={p.sourceNewPurchase}/>
                                    <Checkbox id="sourceRenewal" onChange={toggleCheckbox}
                                              label={t("invoiceprocessing.Renewal")} checked={p.sourceRenewal}/>
                                    <Checkbox id="sourceUsage" onChange={toggleCheckbox}
                                              label={t("invoiceprocessing.Usage")} checked={p.sourceUsage}/>
                                </fieldset>
                            </div>

                            <fieldset className="d-grid col gap-8">
                                <label><b>{t("invoiceprocessing.selection")}</b></label>
                                <Checkbox id="onlyAO" onChange={toggleCheckbox}
                                          label={t("invoiceprocessing.accountingoffice")} checked={p.onlyAO}/>
                                <Checkbox id="normalCustomers" onChange={toggleCheckbox}
                                          label={t("invoiceprocessing.normalcustomers")} checked={p.normalCustomers}/>

                                <Checkbox id="showPackages" onChange={toggleCheckbox}
                                          label={t("invoiceprocessing.package")}
                                          checked={p.showPackages}/>
                                {p.showPackages && packages ?
                                    <PackageSelector packages={packages} onChange={selectPackage} defaultPackageId={p.packageId}></PackageSelector> : <></>}

                                <Checkbox id="showProducts" onChange={toggleCheckbox}
                                          label={t("invoiceprocessing.product")}
                                          checked={p.showProducts}/>
                                {p.showProducts && products ?
                                    <PackageSelector packages={products} onChange={selectProduct} defaultPackageId={p.productId}></PackageSelector> : <></>}

                                <Checkbox id="showCustomerNumbers" onChange={toggleCheckbox}
                                               label={t("invoiceprocessing.customernumbers")}
                                               checked={p.showCustomerNumbers}/>
                                {renderCustomerNumbers()}
                            </fieldset>

                            <fieldset className="d-grid col gap-8">
                                <label><b>{t("invoiceprocessing.amountlimit")}</b></label>
                                <Checkbox id="amountLimitSelected" onChange={toggleCheckbox}
                                          label={`${t("invoiceprocessing.amountlimit")} ${p.amountLimit} ${t("invoiceprocessing.sek")}`} checked={p.amountLimitSelected}/>
                                {/*<Checkbox id="accumulated3months" onChange={toggleCheckbox}*/}
                                {/*          label={t("invoiceprocessing.accumulated3months")} checked={p.accumulated3months}/>*/}
                            </fieldset>

                            {featTS ?
                            <fieldset className="d-grid col gap-8">
                                <label><b>Transaction Source</b></label>
                                <Checkbox id="tranSourceVoss" onChange={toggleCheckbox}
                                          label="Voss" checked={p.tranSourceVoss}/>
                                <Checkbox id="tranSourceVon" onChange={toggleCheckbox}
                                          label="Von" checked={p.tranSourceVon}/>
                            </fieldset> : <></>}

                            <div>
                                <button className="btn btn-default"
                                        onClick={a => createInvoicing()}>{t("invoiceprocessing.createinvoicing")}</button>

                                {confirmation ? <ConfirmModal {...confirmation}
                                              buttonTexts={[t("invoiceprocessing.yes"), t("invoiceprocessing.cancel")]}/> : <> </>}
                            </div>

                        </Card.Content>
                    </Card>
                </Grid.Row.Column>

                <Grid.Row.Column>
                    <Card>
                        <Card.Header>
                            <Card.Header.Title>{t("invoiceprocessing.invoicings")}</Card.Header.Title>
                            <div className="w-100">
                                <div className="float-right"><SkynetIcon icon="vismaicon-refresh" onClick={refreshInvoiceProcessings}/></div>
                            </div>
                        </Card.Header>
                        <Card.Content>
                        {renderInvoiceTable(invoiceProcessings)}
                        </Card.Content>
                    </Card>
                </Grid.Row.Column>
            </Grid.Row>
        </Grid>
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        invoiceProcessings: state.SettingsReducer.invoiceProcessings,
        targetGroups: state.TargetGroupsReducer.targetGroups,
        dataLoading: state.CommonReducer.dataLoading,
        parameters: state.SettingsReducer.invoiceProcessingParameters,
        packages: state.PackageReducer.packageNames,
        products: state.PackageReducer.productNames,
        showModal: state.CommonReducer.showModal
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        targetGroupActions: bindActionCreators(targetGroupActions, dispatch),
        packageActions: bindActionCreators(packageActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InvoicingContainer));