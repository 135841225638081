import { VossScheduledChangeStatus } from "../../../../Constants/VOSS/vossScheduledChangeStatus";
import helpers from "../../../../Utils/helpers";
import SkynetIcon from "../../../Fundamentals/SkynetIcon";

export function ScheduledSubscriptionChanges({ ...props }) {
    const { subscription, subscriptionActions} = props;

    const onDelete = (change, label) => {
       subscriptionActions.updateScheduledChange(subscription.id, change, label, () => subscriptionActions.getSubscription(subscription.id));
    };

    const renderChange = (label, name, scheduledDate, onDelete) => (
        <div className="d-flex gap-12 align-items-center">
            <p className="mb-0">
                Change to {label} <span className="bold">{name}</span> scheduled for{" "}
                {helpers.formatDate(scheduledDate)}
            </p>
            <SkynetIcon
                icon="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-cancel-circle"
                onClick={onDelete}
            />
        </div>
    );

    const renderScheduledChanges = (scheduledChanges) => {
        return scheduledChanges.map((change, index) => (
            <div key={index} className="d-flex flex-column gap-8">
                {change.tier && renderChange("tier", change.tier.name, change.scheduledDate, () => onDelete(change, "tier"))}
                {change.plan && renderChange("plan", change.plan.name, change.scheduledDate, () => onDelete(change, "plan"))}
            </div>
        ));
    };

    return (
        <div>
            <fieldset className="d-grid col gap-16 mb-0 pl-0">
                <legend className="mb-12">Scheduled changes</legend>
                {subscription.scheduledChanges.filter(x => x.status === VossScheduledChangeStatus.Pending).length > 0
                    ? renderScheduledChanges(subscription.scheduledChanges)
                    : <em>No scheduled changes</em>}
            </fieldset>
        </div>
    );
}