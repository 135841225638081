export const leadActionTypes = {
    STORE_MY_LEADS: "STORE_MY_LEADS",
    STORE_MY_CLOSED_LEADS: "STORE_MY_CLOSED_LEADS",
    STORE_MY_EXTERNAL_PARTNERS: "STORE_MY_EXTERNAL_PARTNERS",
    STORE_MY_CUSTOMER_LEADS: "STORE_MY_CUSTOMER_LEADS",
    STORE_LEADS: "STORE_LEADS",
    STORE_LEAD: "STORE_LEAD",
    STORE_CUSTOMER_LEADS: "STORE_CUSTOMER_LEADS",
    UPDATE_LEADS_COUNT: "UPDATE_LEADS_COUNT",
    CLEAR_LEAD: "CLEAR_LEAD",
    CLEAR_LEADS: "CLEAR_LEADS",
    SELECTED_LEADS: "SELECTED_LEADS",
    CLEAR_SELECTED_LEADS: "CLEAR_SELECTED_LEADS",
    STORE_LEAD_TYPES: "STORE_LEAD_TYPES",
    STORE_SEARCH_MY_LEADS_RESULTS: "STORE_SEARCH_MY_LEADS_RESULTS",
    STORE_CUSTOMERS_FOR_LEAD_EMAIL: "STORE_CUSTOMERS_FOR_LEAD_EMAIL",
    CLEAR_SEARCH_RESULTS: "CLEAR_SEARCH_RESULTS",
    STORE_AO_LEADS: "STORE_AO_LEADS",
    STORE_OPEN_AO_LEADS: "STORE_OPEN_AO_LEADS",
    STORE_INTERNAL_LEADS: "STORE_INTERNAL_LEADS",
    STORE_AO_SALES_PERSONS: "STORE_AO_SALES_PERSONS",
    STORE_INTERNAL_EMAILS: "STORE_INTERNAL_EMAILS",
    STORE_EXTERNAL_PARTNERS: "STORE_EXTERNAL_PARTNERS",
    STORE_AO_LEAD: "STORE_AO_LEAD",
    CLEAR_AO_LEAD: "CLEAR_AO_LEAD",
    STORE_INTERNAL_LEAD: "STORE_INTERNAL_LEAD",
    CLEAR_INTERNAL_LEAD: "CLEAR_INTERNAL_LEAD",
    STORE_AO_LEADS_SEARCH_RESULTS: "STORE_AO_LEADS_SEARCH_RESULTS",
    CLEAR_AO_LEADS_SEARCH_RESULTS: "CLEAR_AO_LEADS_SEARCH_RESULTS",
    STORE_INTERNAL_LEADS_SEARCH_RESULTS: "STORE_INTERNAL_LEADS_SEARCH_RESULTS",
    CLEAR_INTERNAL_LEADS_SEARCH_RESULTS: "CLEAR_INTERNAL_LEADS_SEARCH_RESULTS",
    UPDATE_AO_LEADS_LIST: "UPDATE_AO_LEADS_LIST",
    UPDATE_INTERNAL_LEADS_LIST: "UPDATE_INTERNAL_LEADS_LIST",
    STORE_AO_CUSTOMER_LEADS: "STORE_AO_CUSTOMER_LEADS",
    CUSTOMER_TRIALS: "CUSTOMER_TRIALS"
}