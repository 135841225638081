import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ProductSummaryCard } from "../ProductSummary";
import helpers from "../../../../Utils/helpers";
import { VossProductType } from "../../../../Constants/VOSS/vossProductType";
import Tooltip, { TooltipContent, TooltipTrigger } from "../../../Overlays/Tooltip";
import SkynetIcon from "../../../Fundamentals/SkynetIcon";

export function VossProductSummaryCard({ ...props }) {
    const { product } = props;
    const { t } = useTranslation();

    let mainItems = product.priceCalculation.billingItems.filter(item => item.type === VossProductType.Main);
    let addonItems = product.priceCalculation.billingItems.filter(item => item.type === VossProductType.Addon || item.type === VossProductType.License);

    let hasDiscount = product.priceCalculation.discountAgreementIds.length > 0;

    return (
        <ProductSummaryCard product={product}>
            {mainItems.length > 0 && (
                <ProductSummaryCard.Table className={'table-active'}>
                    <ProductSummaryCard.Table.THeader>
                        <th className="col-8">{t('productsummary.included')}</th>
                        {hasDiscount && <th className="col-2">{t('productsummary.discountamount')}</th>}
                        <th className="col-2">{t('productsummary.price')}</th>
                    </ProductSummaryCard.Table.THeader>
                    <ProductSummaryCard.Table.TBody>
                        {mainItems.map((item) => (
                            <TableRow key={item.id} item={item} {...props} />
                        ))}
                    </ProductSummaryCard.Table.TBody>
                </ProductSummaryCard.Table>
            )}
            {addonItems.length > 0 && (
                <ProductSummaryCard.Table className={'table-active'}>
                    <ProductSummaryCard.Table.THeader>
                        <th className="col" colSpan="3">{t('productsummary.optional')}</th>
                    </ProductSummaryCard.Table.THeader>
                    <ProductSummaryCard.Table.TBody>
                        {addonItems.map((item) => (
                            <TableRow key={item.id} item={item} {...props} />
                        ))}
                    </ProductSummaryCard.Table.TBody>
                </ProductSummaryCard.Table>
            )}
            <ProductSummaryCard.Table className="border-0 shadow-0">
                <ProductSummaryCard.Table.THeader>
                    <th className="col-8"></th>
                    {hasDiscount && <th className="col-2">{helpers.currencyFormatter(product.priceCalculation.totalDiscountAmount)}</th>}
                    <th className="col-2">{helpers.currencyFormatter(product.priceCalculation.totalChangedPrice)}</th>
                </ProductSummaryCard.Table.THeader>
            </ProductSummaryCard.Table>
            <div className="d-flex d-flex flex-row justify-content-end my-24 mr-24 align-items-center">
                <ProductSummaryCard.Actions {...props} />
            </div>
        </ProductSummaryCard>
    );
}

function TableRow({ item, ...props }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const { product } = props;

    let products = product.offer.tiers.find(tier => tier.id === product.priceCalculation.tierId)?.productGroups.find(pg => pg.id === item.productGroupId)?.products;

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    const renderDiscountTooltip = () => (
        <Tooltip>
            <TooltipTrigger attributes={{ className: "max-w-max-content d-flex align-items-center" }}>
                {<><span className="mr-6">{helpers.currencyFormatter(item.discountAmount)}</span><SkynetIcon icon={'vismaicon-sm vismaicon-info vismaicon-dynamic '}></SkynetIcon></>}
            </TooltipTrigger>
            <TooltipContent>
                <strong>Utan rabatt</strong><br /> <span>{helpers.currencyFormatter(item.amount)}</span>
            </TooltipContent>
        </Tooltip>
    );

    return (
        <>
            <tr key={item.id} onClick={toggleExpanded}>
                <td className={`col-8 ${isExpanded ? 'bold' : ''}`}>
                    {item.quantity <= 0
                        ? <s>{item.name}</s>
                        : `${item.name} ${item.quantity > 0 && item.type === VossProductType.License ? `- ${item.quantity}` : ''}`
                    }
                </td>
                {product.priceCalculation.discountAgreementIds.length > 0 && <td className="col-2">{item.discountAmount > 0 ? renderDiscountTooltip() : helpers.currencyFormatter(item.discountAmount)}</td>}
                <td className="col-2">{helpers.currencyFormatter(item.totalAmount)}</td>
            </tr>
            {isExpanded && (
                <tr className="skynet-no-pointer-events">
                    <td colSpan="3">
                        <div>
                            {products.map((product, index) => (
                                <div key={index} className="d-flex justify-content-between">
                                    <span>{product.name}</span>
                                </div>
                            ))}
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
};
