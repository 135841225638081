import { useEffect } from "react"
import { useTranslation } from "react-i18next";
import { FormInput } from "../../FormComponents/Input/Input";
import { Dropdown } from "../../FormComponents/Dropdown";
import Editor from "../../Editor";

export const SendInternalLeadForm = ({ ...props }) => {

    const { t } = useTranslation();
    const { customer, internalLeadForm, lead, leadActions, salesPersons, onChange, commonActions } = props;

    useEffect(() => {
        leadActions.getAoSalesPersons();
        leadActions.beginInternalLeadForm(customer, lead);

        return () => {
            commonActions.cancelFormEditing("internalLeadEmail");
        }
    }, [])

    const priorityTypes = [
        { id: 'high', name: t('leadsendemailmodal.high') },
        { id: 'medium', name: t('leadsendemailmodal.medium') },
        { id: 'low', name: t('leadsendemailmodal.low') },
    ];

    if (!internalLeadForm || !internalLeadForm.data)
        return;

    let data = internalLeadForm.data;

    return (
        <form className="skynet-form">
            <fieldset className="d-grid gap-16 mb-0">
                <FormInput required label={t('leadsendemailmodal.customernumber')} value={data.customerNumber} onChange={(value) => onChange('internalLeadEmail', 'customerNumber', value)} />
                <FormInput required label={t('leadsendemailmodal.phonenumber')} value={data.phoneNumber} onChange={(value) => onChange('internalLeadEmail', 'phoneNumber', value)} />
                <FormInput required label={t('leadsendemailmodal.contactperson')} value={data.contactPerson} onChange={(value) => onChange('internalLeadEmail', 'contactPerson', value)} />
                <FormInput required label={t('leadsendemailmodal.email')} value={data.contactEmail} onChange={(value) => onChange('internalLeadEmail', 'contactEmail', value)} />
                {lead.id === 'aoLead' && (
                    <Dropdown label={t('leadsendemailmodal.assignto')} options={salesPersons} value={data.assignedUserId != 0 ? data.assignedUserId : -1} onChange={(value) => onChange('internalLeadEmail', 'assignedUserId', value)} />
                )}
                {lead.isInternal && (
                    <Dropdown label={t('leadsendemailmodal.priority')} blankOption options={priorityTypes} value={data.priorityType} onChange={(value) => onChange('internalLeadEmail', 'priorityType', value)} />
                )}
                <div className="form-control skynet-form-input required">
                    <label>{t('leadsendemailmodal.description')}</label>
                    <Editor type='textarea' label={t('leadsendemailmodal.description')} value={data.body} onChange={(value) => onChange('internalLeadEmail', 'body', value)} />
                </div>
            </fieldset>
        </form>
    )
}