import { bindActionCreators } from "redux";
import Card from "../../../../Components/Card/Card";
import { connect } from "react-redux";
import customerActions from "../../../../Actions/customerActions";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import VonEditEmployeeDetailsForm from "../../../../Components/Customer/VON/EditEmployee/VonEditEmployeeDetailsForm";
import VonEditAccessRightsForm from "../../../../Components/Customer/VON/EditEmployee/VonEditAccessRightsForm";
import VonEditEmployeeTypeForm from "../../../../Components/Customer/VON/EditEmployee/VonEditEmployeeTypeForm";
import routes from "../../../../Constants/routes";
import commonActions from "../../../../Actions/commonActions";
import { VonAccessRights } from "../../../../Constants/VON/EmployeeForm/vonAccessRights";
import Loader from "../../../../Components/Loader";
import VonEditEmployeeRolesForm from "../../../../Components/Customer/VON/EditEmployee/VonEditEmployeeRolesForm";
import AlertSmall from "../../../../Components/AlertSmall";

function VonEditEmployeeFormContainer({ customerActions, commonActions, editVonEmployeeForm, editVonEmployeeRolesForm, customer, vonProducts, vonProductsLoading, vonEmployeeForEditLoading, ...props }) {
    const { id } = useParams();
    const customerNumber = customer.vbCustomer?.customerNumber ?? customer.vossCustomer?.customerNumber;
    const navigate = useNavigate();

    useEffect(() => {
        if (vonProducts && !editVonEmployeeForm)
            customerActions.getVonEmployeeForEdit(customerNumber, vonProducts, id);

    }, [vonProductsLoading])

    useEffect(() => {
        if (vonProducts)
            customerActions.getVonEmployeeForEdit(customerNumber, vonProducts, id);

        return () => {
            commonActions.cancelFormEditing('editVonEmployeeForm');
            commonActions.cancelFormEditing('editVonEmployeeRolesForm');
        }
    }, [])

    const onChange = (form, value, field) => {
        commonActions.changeMultiFormField(form, field, value);
    }

    const callback = () => {
        navigate(routes.customerVonAccounts(customer.pathId));
        customerActions.getCustomerVonProducts(customerNumber);
        customerActions.getVonAccounts(customerNumber);
    }

    const onSubmit = () => {
        switch (editVonEmployeeForm.data.onlineRoleId) {
            case VonAccessRights.Delete:
                customerActions.deleteVonEmployee(id, () => callback());
                break;
            case VonAccessRights.Inactive:
                customerActions.inactivateVonEmployee(id, () => callback());
                break;
            default:
                customerActions.updateVonEmployee(editVonEmployeeForm, editVonEmployeeRolesForm, () => callback());
                break;
        }
    }

    const isPrimaryContact = editVonEmployeeForm?.data?.isPrimaryContact;

    let isLoading = vonProductsLoading || vonEmployeeForEditLoading;

    return <Card>
        <Card.Header>
            <Card.Header.Title>Editera användare</Card.Header.Title>
        </Card.Header>
        <Card.Content isLoading={isLoading}>
            <div className="d-grid gap-24">
                <div className="d-flex">
                    <fieldset className="d-grid col gap-24 mb-0 pl-0">
                        {editVonEmployeeForm && <>
                            <VonEditEmployeeDetailsForm data={editVonEmployeeForm.data} onChange={onChange} />
                            <VonEditEmployeeTypeForm data={editVonEmployeeForm.data} onChange={onChange} />
                            <VonEditAccessRightsForm data={editVonEmployeeForm.data} onChange={onChange} />
                        </>}
                        {isPrimaryContact && <div>
                            <AlertSmall alert={{ type: "warning", title: "Användaruppgifter kan inte editeras!", message: "Du måste först markera en annan användare som huvudkontakt" }} />
                        </div>}
                    </fieldset>
                    <fieldset className="d-grid col gap-24 mb-0 pl-0">
                        {editVonEmployeeRolesForm && <>
                            <VonEditEmployeeRolesForm data={editVonEmployeeRolesForm.data} vonProducts={vonProducts} onChange={onChange} {...props} />
                        </>}
                    </fieldset>
                </div>
            </div>
        </Card.Content>
        <Card.Footer>
            <Card.Footer.Buttons>
                <button className='btn btn-default' disabled={isLoading} onClick={() => onSubmit()}>Spara</button>
                <button className='btn btn-default'
                    onClick={() => navigate(routes.customerVonAccounts(customer.pathId))}>Avbryt
                </button>
            </Card.Footer.Buttons>
        </Card.Footer>
    </Card>
}

const mapStateToProps = (state) => {
    return {
        editVonEmployeeForm: state.CommonReducer.forms && state.CommonReducer.forms.editVonEmployeeForm,
        editVonEmployeeRolesForm: state.CommonReducer.forms && state.CommonReducer.forms.editVonEmployeeRolesForm,
        vonAccounts: state.CustomerReducer.vonAccounts,
        isLoading: state.CommonReducer.isLoading,
        customer: state.CustomerReducer.customer,
        vonProducts: state.CustomerReducer.vonProducts,
        vonProductsLoading: state.CommonReducer.dataLoading.vonProducts,
        vonEmployeeForEditLoading: state.CommonReducer.dataLoading.vonEmployeeForEdit
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VonEditEmployeeFormContainer);