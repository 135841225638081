import { httpFetch } from "../../Utils/httpUtils";
import { subscriptionActionTypes as actionTypes } from "../../ActionTypes/Voss/subscriptionActionTypes";
import commonActions from "../commonActions";
import { commonActionTypes } from "../../ActionTypes/commonActionTypes";
import { VossScheduledChangeStatus } from "../../Constants/VOSS/vossScheduledChangeStatus";

const subscriptionActions = {

    getSubscription: (id) => {
        return (dispatch) => {
            dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "subscription", value: true });
            return httpFetch(`/api/subscriptions/GetSubscription/${id}`, dispatch)
                .then(json => {
                    dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "subscription", value: false });
                    dispatch({ type: actionTypes.STORE_SUBSCRIPTION, data: json });
                })
        }
    },

    getSubscriptionProductGroups: (id, status) => {
        return (dispatch) => {
            dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "productGroups", value: true });
            return httpFetch(`/api/subscriptions/GetSubscriptionProductGroups/${id}/${status}`, dispatch)
                .then(json => {
                    dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "productGroups", value: false });
                    dispatch({ type: actionTypes.STORE_SUBSCRIPTION_PRODUCT_GROUPS, data: json });
                })
        }
    },

    getIntermediaries() {
        return (dispatch) => {
            return httpFetch("/api/subscriptions/GetIntermediaries", dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_INTERMEDIARIES, data: json });
                })
        }
    },

    initializeSubscriptionProductGroupsForm: (subscriptionId) => {
        return (dispatch) => {
            dispatch(commonActions.startFormEditing("activateSubscriptionProductGroups", { productGroups: [], id: subscriptionId }));
        }
    },

    initializeModifySubscriptionForm: (subscription) => {
        return (dispatch) => {
            let scheduledChange = subscription.scheduledChanges.find(x => x.status === VossScheduledChangeStatus.Pending);
            dispatch(commonActions.startFormEditing("modifySubscription", {
                planId: scheduledChange && scheduledChange.plan ? scheduledChange.plan.id : subscription.plan.id,
                tierId: scheduledChange && scheduledChange.tier ? scheduledChange.tier.id : subscription.tier.id,
                intermediaryInvoicingId: subscription.intermediary.invoicingId,
                id: subscription.id
            }));
        }
    },

    initializeSubscriptionEditForms: (subscription) => {
        return (dispatch) => {
            dispatch(subscriptionActions.initializeModifySubscriptionForm(subscription));
            dispatch(subscriptionActions.initializeSubscriptionProductGroupsForm(subscription.id));
        }
    },

    cancelSubscriptionEditForms: () => {
        return (dispatch) => {
            dispatch(commonActions.cancelFormEditing("changeSubscriptionPlan"));
            dispatch(commonActions.cancelFormEditing("activateSubscriptionProductGroups"));
        }
    },

    activateSubscriptionProductGroups: (form, callback) => {
        const data = form.data;
        return (dispatch) => {
            dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "productGroups", value: true });
            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data.productGroups)
            };
            return httpFetch(`/api/subscriptions/ActivateSubscriptionProductGroups/${data.id}/`, dispatch, httpRequest)
                .then(json => {
                    dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "productGroups", value: false });
                    dispatch(commonActions.showSuccess('Product groups activated successfully!'));
                    callback();
                })
        }
    },

    terminateSubscriptionProductGroup: (id, productGroupId, callback) => {
        return (dispatch) => {
            dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "productGroups", value: true });
            return httpFetch(`/api/subscriptions/TerminateSubscriptionProductGroup/${id}/${productGroupId}`, dispatch, { method: 'POST' })
                .then(json => {
                    dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "productGroups", value: false });
                    dispatch(commonActions.showSuccess('Product group terminated successfully!'));
                    callback();
                })
        }
    },

    deleteProductGroupTermination: (id, productGroupId, callback) => {
        return (dispatch) => {
            return httpFetch(`/api/subscriptions/DeleteProductGroupTermination/${id}/${productGroupId}`, dispatch, { method: 'DELETE' })
                .then(json => {
                    dispatch(commonActions.showSuccess('Product group termination cancelled successfully!'));
                    callback();
                })
        }
    },

    updateScheduledChange: (subscriptionId, change, label, callback) => {
        const httpRequest = {
            method: 'PUT',
            body: JSON.stringify({
                tierId: label === 'tier' ? null : change.tier?.id,
                planId: label === 'plan' ? null : change.plan?.id,
                type: change.type
            })
        };
        return (dispatch) => {
            dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "subscription", value: true });
            return httpFetch(`/api/subscriptions/UpdateSubscriptionScheduledChange/${subscriptionId}/${change.id}`, dispatch, httpRequest)
                .then(json => {
                    dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "subscription", value: false });
                    dispatch(commonActions.showSuccess('Scheduled change updated successfully!'));
                    callback();
                })
        }
    },

    modifySubscription: (form, callback) => {
        const data = form.data;
        return (dispatch) => {
            dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "subscription", value: true });
            return httpFetch(`/api/subscriptions/ModifySubscription/${data.id}`, dispatch, { method: 'PUT', body: JSON.stringify(data) })
                .then(json => {
                    dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "subscription", value: false });
                    dispatch(commonActions.showSuccess('Subscription modified successfully!'));
                    callback();
                })
        }
    },

    clear: (key) => {
        return (dispatch) => {
            dispatch({ type: actionTypes.CLEAR, key });
        }
    },

}

export default subscriptionActions;
