import { Typeahead } from "react-bootstrap-typeahead";

export function IntermediarySelector({ intermediaries, onChange, value }) {

    const selectIntemediary = (value) => {
        if (value.length) {
            onChange(value[0].invoicingId);
        }
    }

    const attributes = {
        labelKey: option => `${option.name + " - " + option.invoicingId}`,
        options: intermediaries,
        placeholder: "Välj förmedlare/partner...",
        className: "form-group px-0 mb-0",
        onChange: (selected) => selectIntemediary(selected),
        defaultSelected: intermediaries.filter(x => x.invoicingId === value)
    };

    return <Typeahead {...attributes} />
}