import Card from "../../../Card/Card";
import Checkbox from "../../../Checkbox";
import { useTranslation } from "react-i18next";
import { VossProductGroupType } from "../../../../Constants/VOSS/vossProductGroupType";
import { vossCustomerSubscriptionStatus } from "../../../../Constants/VOSS/vossCustomerSubscriptionStatus";
import { IntermediarySelector } from "../../../Voss/IntermediarySelector";
import { VossProductType } from "../../../../Constants/VOSS/vossProductType";

function VossTierOptions({ product, onChange, intermediaries }) {
    const { t } = useTranslation();

    const handleProductGroupChange = (productGroup, isChecked) => {
        if (isChecked) {
            onChange("optionalProductGroups", [
                ...product.priceCalculation.optionalProductGroups,
                productGroup
            ]);
        } else {
            var licenseProducts = productGroup.products.filter(product => product.type === VossProductType.License);
            product.priceCalculation.licenses = product.priceCalculation.licenses.filter(x => !licenseProducts.some(lp => lp.productId === x.productId));
            onChange("optionalProductGroups", product.priceCalculation.optionalProductGroups.filter(x => x.id != productGroup.id));
        }
    };

    let tier = product.offer.tiers.find(tier => tier.id === product.priceCalculation.tierId);
    let selectableProductGroups = tier.productGroups
        .filter(x => x.productGroupType !== VossProductGroupType.Included);
    let selectablePlans = product.offer.plans.filter(plan => tier.applicablePlans.includes(plan.id));

    const activeSubscription = product.offer.subscriptionStatus === vossCustomerSubscriptionStatus.Active;

    return <fieldset className="d-grid col gap-24 mb-0 px-0">
        <div>
            <h3 className="font-family-ubuntu-semibold mb-12">{t("vosstieroptions.plan")}</h3>
            <fieldset className={`d-grid col gap-12 mb-0 px-0 ${activeSubscription ? 'disabled' : ''}`}>
                {selectablePlans.map((plan, index) => (
                    <div key={index} className="radio mb-0">
                        <input type="radio" name="plan" value={plan.id} id={index} checked={plan.id === product.priceCalculation.planId} onChange={() => onChange("planId", plan.id)} />
                        <label htmlFor={index}>{plan.name}</label>
                    </div>
                ))}
            </fieldset>
        </div>
        {selectableProductGroups.length > 0 && <div>
            <h3 className="font-family-ubuntu-semibold mb-12">{t("vosstieroptions.optional")}</h3>
            <fieldset className="d-grid col gap-12 mb-0 px-0">
                {selectableProductGroups
                    .map((productGroup, index) => (
                        <div key={index} className="mb-0">
                            <Checkbox
                                disabled={productGroup.customerSubscriptionStatus === vossCustomerSubscriptionStatus.Active}
                                label={productGroup.name}
                                checked={product.priceCalculation.optionalProductGroups?.some(pg => pg.id === productGroup.id)}
                                onChange={(e) => handleProductGroupChange(productGroup, e.checked)}
                            />
                        </div>))}
            </fieldset>
        </div>}
        {intermediaries && <div>
            <h3 className="font-family-ubuntu-semibold mb-12">{t("vosstieroptions.intermediary")}</h3>
            <IntermediarySelector intermediaries={intermediaries} onChange={(value) => onChange('intermediaryInvoicingId', value)} value={product.priceCalculation.intermediaryInvoicingId} />
        </div>}
    </fieldset>
};

export function VossTierOptionsCard({ ...props }) {
    const { t } = useTranslation();

    return <Card>
        <Card.Header>
            <Card.Header.Title>{t('vosstieroptions.title')} </Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <VossTierOptions {...props} />
        </Card.Content>
    </Card>
}