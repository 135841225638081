import { subscriptionActionTypes as actionTypes } from "../../ActionTypes/Voss/subscriptionActionTypes";
import { initialState } from "../CommonReducer";

export default function SubscriptionReducer(state = { ...initialState }, action) {

    let newState = { ...state };

    switch (action.type) {
        case actionTypes.STORE_SUBSCRIPTION:
            newState.subscription = action.data;
            break;
        case actionTypes.STORE_SUBSCRIPTION_PRODUCT_GROUPS:
            newState.subscriptionProductGroups = action.data;
            break;
        case actionTypes.CLEAR:
            delete newState[action.key];
            break;
        case actionTypes.STORE_INTERMEDIARIES:
            newState.intermediaries = action.data;
            break;
        default:
            return state;
    }

    return newState;
}