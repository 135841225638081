import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import Modal from '../../Modal';
import { Dropdown } from '../../FormComponents/Dropdown';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import customerActions from '../../../Actions/customerActions';
import commonActions from '../../../Actions/commonActions';
import leadActions from '../../../Actions/leadActions';
import { SendInternalLeadForm } from '../Leads/SendInternalLeadForm';
import { SendExternalLeadForm } from '../Leads/SendExternalLeadForm';

const LeadSendEmailModal = ({ customer, ...props }) => {

    const { t } = useTranslation();
    const { commonActions, customerActions, internalLeadForm, externalLeadForm, onClose } = props;
    const [selectedLead, setSelectedLead] = useState(null);

    const leadList = [
        { id: 'aoLead', name: t('leadsendemailmodal.aoleads'), isInternal: true },
        { id: 'externalLead', name: t('leadsendemailmodal.externalleads') },
        { id: 'smbLead', name: t('leadsendemailmodal.smbleads') },
        { id: 'tidSmartLead', name: t('leadsendemailmodal.tidsmartleads'), isInternal: true },
        { id: 'supportLead', name: t('leadsendemailmodal.supportleads'), isInternal: true },
    ];

    const onSelectLead = (leadId) => {
        let lead = leadList.find(l => l.id === leadId);
        setSelectedLead(lead);
    }

    const handleFieldChange = (form, field, value) => {
        commonActions.changeMultiFormField(form, field, value);
    };

    const onSave = () => {
        if (selectedLead.id === 'externalLead')
            customerActions.sendExternalLeadEmail(externalLeadForm, onClose);
        else
            customerActions.sendInternalAoLeadEmail(internalLeadForm, onClose);
    };

    const modalProps = {
        mainButtonTitle: t('leadsendemailmodal.send'),
        stateful: true,
        onCancelClick: onClose,
        onSaveClick: onSave,
        open: true,
        title: selectedLead ? t('leadsendemailmodal.send') + ' ' + selectedLead.name : t('leadsendemailmodal.sendlead'),
        showButtons: selectedLead
    };

    return <Modal {...modalProps}>
        <div className='mb-24'>
            {!selectedLead ? <div className='d-grid gap-24'>
                <div>
                    <Dropdown label={t('leadsendemailmodal.selectleadtype')} options={leadList} onChange={(value) => onSelectLead(value)} />
                </div>
                <div>
                    <h1>{t('leadsendemailmodal.aoleads')}</h1>
                    <p>{t('leadsendemailmodal.aoleadstext')}</p>
                    <h1>{t('leadsendemailmodal.externalleads')}</h1>
                    <p>{t('leadsendemailmodal.externalleadstext')}</p>
                    <h1>{t('leadsendemailmodal.smbleads')}</h1>
                    <p>{t('leadsendemailmodal.smbleadstext')}</p>
                    <h1>{t('leadsendemailmodal.internalleads')}</h1>
                    <p>{t('leadsendemailmodal.internalleadstext')}</p>
                </div>
            </div>
                : selectedLead.id !== 'externalLead' ? <SendInternalLeadForm customer={customer.vossCustomer ?? customer.vbCustomer} lead={selectedLead} onChange={handleFieldChange} {...props} />
                    : <SendExternalLeadForm customer={customer.vossCustomer ?? customer.vbCustomer} onChange={handleFieldChange} {...props} />}
        </div>
    </Modal>
};

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        salesPersons: state.LeadsReducer.aoSalesPersons,
        externalPartners: state.LeadsReducer.externalPartners,
        internalLeadForm: state.CommonReducer.forms && state.CommonReducer.forms.internalLeadEmail,
        externalLeadForm: state.CommonReducer.forms && state.CommonReducer.forms.externalLeadEmail,
        customer: state.CustomerReducer.customer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        leadActions: bindActionCreators(leadActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadSendEmailModal));