import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import helpers from "../../Utils/helpers";

function CustomerCategoryList({ customerCategories, onDelete }) {

    return (
        <div className="form-group">
            <div className='form-group d-flex mb-24'>
                <div className="form-group search-group">
                    <input className="form-control" placeholder="Sök kategori" type="text" />
                    <span className="search-icon" />
                    <span className='clear-search' />
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-stripe table-hover" style={{ wordBreak: "break-all" }}>
                    <thead>
                        <tr>
                            <th>Kundkat.</th>
                            <th>Fr.o.m</th>
                            <th>T.o.m</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {customerCategories && customerCategories.map((category, index) => {
                            return (
                                <tr key={index}>
                                    <td>{category.firstIncludedCustomerCategory + " - " + category.lastIncludedCustomerCategory}</td>
                                    <td>{helpers.formatDate(category.validFromDate)}</td>
                                    <td>{helpers.formatDate(category.validToDate)}</td>
                                    <td>
                                        <span className="vismaicon vismaicon-delete" onClick={() => onDelete(index)}/>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerCategoryList));