import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import helpers from '../../Utils/helpers';
import Tooltip, {TooltipContent, TooltipTrigger} from '../../Components/Overlays/Tooltip';
import { InfoLabel } from '../../Components/Badges/InfoLabel';
import {bindActionCreators} from "redux";
import commonActions from "../../Actions/commonActions";
import customerActions from "../../Actions/customerActions";
import targetGroupHelpers from "../../Utils/targetGroups";
import TargetGroups from "../../Utils/targetGroups";

function CustomerInfoFlag({type, title, onClick}) {
    return (
        <div className="d-inline-block position-relative infolabel ml-8">
            <InfoLabel type={type} title={title} onClick={onClick}/>
        </div>
    )

}

function CustomerInfoFlagsContainer({ customerInfoFlags, customerActions, infoFlagsLoading, relatedCustomersLoading, vbCustomer, customer, relatedCustomers, onClick, customerInfoCounts }) {
    
    const isAoCustomer = helpers.isAoCustomer(vbCustomer)

       useEffect(() => {
           if (!customerInfoFlags && !infoFlagsLoading) {
               customerActions.getCustomerInfoFlags(customer);
           }

           if (!relatedCustomers && !relatedCustomersLoading && isAoCustomer) {
                customerActions.getRelatedCustomers(vbCustomer.customerNumber);
           }
       }, [])
    
    const templateTypes = {
        GrowthProgramLead: "GrowthProgramLead",
        AOPartnerLead: "AOPartnerLead"
    }
    const isPartnerCustomer = (customer) => {
        if ((customer.type === 4 || customer.type === 5)
            || (customer.group === 3000 || customer.group === 3020 || customer.group === 3050)) {
            return true
        }
    }

    const aoHasPartnerInfo = () => {
        return relatedCustomers && isAoCustomer && relatedCustomers.some(c => isPartnerCustomer(c));
    }

    const partnerHasAoInfo = () => {
        return relatedCustomers && isPartnerCustomer(vbCustomer) && relatedCustomers.some(c => helpers.isAoCustomer(c));
    }

    const getPartnerColor = (eekonomiCount) => {
        if ((eekonomiCount >= 0 && eekonomiCount <= 6) || (eekonomiCount >= 13 && eekonomiCount <= 16) || (eekonomiCount >= 23 && eekonomiCount <= 46) || eekonomiCount == 52) {
            return {
                backgroundColor: '#7FB435',
                borderColor: '#7FB435',
                color: "#000"
            }
        }

        if ((eekonomiCount >= 7 && eekonomiCount <= 9) || (eekonomiCount >= 17 && eekonomiCount <= 19) || (eekonomiCount >= 47 && eekonomiCount <= 49)) {
            return {
                backgroundColor: '#4D8400',
                borderColor: '#4f8800',
                color: "#000"
            }
        }

        if ((eekonomiCount >= 10 && eekonomiCount <= 12) || (eekonomiCount >= 20 && eekonomiCount <= 22) || (eekonomiCount >= 50 && eekonomiCount <= 52)) {
            return {
                backgroundColor: '#f0ad4e',
                borderColor: '#eea236',
                color: "#000"
            }
        }
    }
    const renderPartnerLevelFlag = (eekonomiCount, partnerLevel) => {
        
        if (eekonomiCount > 0){
            
            const attributes = {
                className: "d-inline-block position-relative infolabel ml-8"
            };

            return (<Tooltip>
                <TooltipTrigger attributes={attributes}>
                    <InfoLabel title={`Partnernivå ${partnerLevel}`} type="success" style={getPartnerColor(eekonomiCount)} />
                </TooltipTrigger>
                <TooltipContent>
                    {`${eekonomiCount} ${t('customerinfoflags.eekonomicollaborations')}`}
                </TooltipContent>
            </Tooltip>)
        }
    }

    const onFlagClick = (e, flag) => {
        e.stopPropagation();
        e.preventDefault();

        switch (flag) {
            case "aopartner":
                return AoPartnerClick();
                break;
            default:
                return;

        }
    }
    const AoPartnerClick = () => onClick("showInternalLeadEmailModal", templateTypes.AOPartnerLead);

    const flagTypeFromStatus = (status) =>  {
        switch (status) {
            case 0:
                return "info"
            case 1:
                return "success"
            case 2:
                return "warning"
            case 3:
                return "danger"
            default:
                return "";
        }
    }
    
    const { t } = useTranslation();
    
    if (infoFlagsLoading ||infoFlagsLoading)
        return <div className="spinner spinner-sm spinner-default-green m-auto d-block"/>
    
    return (<div className="pt-1">
        {customerInfoFlags && customerInfoFlags.map((flag, index) => {
            return <CustomerInfoFlag key={flag.name} type={flagTypeFromStatus(flag.status)} title={t('maininfocontainer.' + flag.name)} onClick={flag.name === 'aopartner' && flag.status !== "success" ? (e) => onFlagClick(e, flag.name) : undefined}/>})
            }
            {customerInfoCounts && renderPartnerLevelFlag(customerInfoCounts.numberOfEekonomiCollaborations, customerInfoCounts.partnerLevel)}
            {aoHasPartnerInfo() && <CustomerInfoFlag type="warning" title={t('maininfocontainer.customeralsoreseller')}/>}
            {partnerHasAoInfo() && <CustomerInfoFlag type="warning" title={t('maininfocontainer.customeralsoao')}/>}
        </div>)
}


function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customer: state.CustomerReducer.customer,
        relatedCustomers: state.CustomerReducer.relatedCustomers,
        targetGroups: state.CommonReducer.dropdowlists?.targetGroups,
        customerInfoFlags: state.CustomerReducer.customerInfoFlags,
        customerInfoCounts: state.CustomerReducer.customerInfoCounts,
        infoFlagsLoading: state.CommonReducer.dataLoading.customerInfoFlags,
        relatedCustomersLoading: state.CommonReducer.dataLoading.relatedCustomers
    }
}
function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerInfoFlagsContainer));