import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import TextInput from '../TextInput'
import DropDown from '../DropDown'
import Loader from '../Loader'
import UserSearch from '../UserSearch'
import { withTranslation } from 'react-i18next';
import customerActions from '../../Actions/customerActions'
import commonActions from '../../Actions/commonActions'
import CustomerSearch from '../Search/CustomerSearch';
import {UserRoles} from "../../Constants/userRoles";
import {hasAdminAccess} from "../../Utils/permissionUtils";

class OldCustomerForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showMainSubCustModal: false
        }
    }

    getCustomerInvoiceFees(customer) {
        var invoiceFees = [
            { id: 8192, name: "Yes" },
            { id: 8200, name: "No" }
        ];

        if (invoiceFees.filter(item => item.id == customer.invoiceFee).length == 0) {
            invoiceFees.push({ id: customer.invoiceFee, name: customer.invoiceFee });
        }

        return invoiceFees;
    }

    onSaveClick() {
        const { commonActions, customerForm, isCreation, callback, customerActions } = this.props;
        
        customerActions.saveCustomer(customerForm, isCreation, callback)
    }

    onCancelClick() {
        const { commonActions, customerForm, callback, isMainPage } = this.props;
        if (isMainPage) {

        }

        commonActions.cancelFormEditing(customerForm.id, callback);
    }

    onFieldChange(field, value) {
        const { commonActions, customerForm } = this.props;

        commonActions.changeMultiFormField(customerForm.id, field, value)
    }

    onCustomerGroupChange(field, value) {
        const { commonActions, customerForm, customerConnections } = this.props;
        
        if (value === 1001 && customerConnections.some(c => c.product === "eEkonomi" && c.type === 1)) {
            commonActions.showWarning("This Company is a Main customer and can't belong to 1001 Customer group");
            return;
        }
        commonActions.changeMultiFormField(customerForm.id, field, value)
    }

    onArrayFieldChange(field, arr, index, value) {

        const { commonActions, customerForm } = this.props;

        commonActions.changeFormArrayField(customerForm.id, field, arr, index, value)
    }

    formatGroupName = (group) => {
        if (group.id > 0) {
            return `${group.name} / ${group.id}`;
        }
    }

    render() {
        const { customerForm, dropdownlists, isCreation, user, t, isMainPage } = this.props;

        if (!customerForm) {
            return null;
        }

        const customerGroups = dropdownlists && dropdownlists.CustomerGroup;
        const customerTypes = dropdownlists && dropdownlists.CustomerType;
        const customerStatuses = dropdownlists && dropdownlists.CustomerStatus;
        const customerGroups2 = dropdownlists && dropdownlists.CustomerGroup2;
        const spcsEdi = dropdownlists && dropdownlists.SpcsEdi;
        const vbCustomer = customerForm.data;
        const paymentTerms = [
            { id: 30, name: "30 dagar netto" },
            { id: 2, name: "2 postförskott" },
            { id: 6, name: "Förskott" },
            { id: 7, name: "Postförskott KK" },
            { id: 8, name: "Förskott swish" }
        ]
        const vbAdmin = hasAdminAccess(user, UserRoles.InternalInfo);
        const fieldsetSize = isMainPage ? "col-xs-12" : "col-xs-6";


        return (
            <form className="form-horizontal customerFormEdit">
                <span className="row">
                    <div className={fieldsetSize}>
                        <div>
                            <h3>{t('internalinfocontainer.header')}</h3>
                            <DropDown label={t('internalinfocontainer.customergroup')} onChange={this.onCustomerGroupChange.bind(this, 'group')} list={customerGroups} value={vbCustomer.group} nameAggregate={this.formatGroupName} />
                            <DropDown label={t('internalinfocontainer.customergroup2')} list={customerGroups2} value={vbCustomer.secondaryGroup} disabled={true} nameAggregate={this.formatGroupName} />
                            <DropDown label={t('internalinfocontainer.customertype')} onChange={this.onFieldChange.bind(this, 'type')} list={customerTypes} value={vbCustomer.type} />
                            {!isCreation &&
                                <div>
                                    <DropDown label={t('internalinfocontainer.customerstatus')} onChange={this.onFieldChange.bind(this, 'status')} list={customerStatuses} value={vbCustomer.status} />
                                    <TextInput label={t('internalinfocontainer.creditlimit')} value={vbCustomer.creditLimit} readonly={!vbAdmin} onChange={this.onFieldChange.bind(this, "creditLimit")} />
                                    {vbAdmin && <>
                                        <DropDown label={t('internalinfocontainer.invoicefee')} onChange={this.onFieldChange.bind(this, "invoiceFee")} list={this.getCustomerInvoiceFees(vbCustomer)} value={vbCustomer.invoiceFee}/>
                                        <DropDown label={t('internalinfocontainer.spcsedi')} onChange={this.onFieldChange.bind(this, "spcsEdi")} list={spcsEdi} value={vbCustomer.spcsEdi} />
                                        <DropDown label={t('internalinfocontainer.paymentterms')} onChange={this.onFieldChange.bind(this, "paymentTerms")} list={paymentTerms} value={vbCustomer.paymentTerms} />
                                    </>}
                                    <CustomerSearch id={"customerToBeInvoiced"} className="customer-form-search" placeholder={""} label={t('internalinfocontainer.invoicecustomernumber')} defaultSelected={vbCustomer.customerToBeInvoiced} onChange={this.onFieldChange.bind(this, "invoiceCustomerNumber")} />
                                    <UserSearch onChange={this.onFieldChange.bind(this, 'responsibleSalesPersonEmail')} defaultSelected={{ name: vbCustomer.responsibleSalesPersonName || "", email: vbCustomer.responsibleSalesPersonEmail }} label={t('internalinfocontainer.responsibleperson')} />
                                </div>
                            }
                        </div>
                        <br />
                    </div>
                    {!isMainPage && <>
                        <div className="col-xs-6">
                            <h3>{t('contactinfocontainer.header')}</h3>
                            <TextInput label={t('contactinfocontainer.contactperson')} onChange={this.onFieldChange.bind(this, 'contactPerson')} value={vbCustomer.contactPerson} />
                            <TextInput label={t('contactinfocontainer.streetaddress')} onChange={this.onFieldChange.bind(this, 'streetAddress')} value={vbCustomer.streetAddress} />
                            <TextInput label={t('contactinfocontainer.coaddress')} onChange={this.onFieldChange.bind(this, 'coAddress')} value={vbCustomer.coAddress} />
                            <TextInput label={t('contactinfocontainer.postalcode')} onChange={this.onFieldChange.bind(this, 'postalCode')} value={vbCustomer.postalCode} />
                            <TextInput label={t('contactinfocontainer.postalcity')} onChange={this.onFieldChange.bind(this, 'postalCity')} value={vbCustomer.postalCity} />
                            <TextInput label={t('contactinfocontainer.emailaddress')} onChange={this.onFieldChange.bind(this, 'emailAddress')} value={vbCustomer.emailAddress} type="email" />
                            <TextInput label={t('contactinfocontainer.phonenumber')} onChange={this.onFieldChange.bind(this, 'phoneNumber')} value={vbCustomer.phoneNumber} type="tel" />
                            <TextInput label={t('contactinfocontainer.invoiceemail')} onChange={this.onFieldChange.bind(this, 'invoiceEmail')} value={vbCustomer.invoiceEmail} type="email" />
                        </div>
                        <div className="clearfix" />
                        <div className="clearfix"></div>
                        <div className="form-group padding-top no-left-margin btn-toolbar">
                            <button type="button" className="btn btn-primary" onClick={this.onSaveClick.bind(this)}>{isCreation ? t('customerform.create') : t('customerform.save')}</button>
                            <button type="button" className="btn btn-default" onClick={this.onCancelClick.bind(this)}>{t('customerform.cancel')}</button>
                            <Loader />
                        </div>
                    </>}
                </span>
            </form>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        customerForm: state.CommonReducer.forms && state.CommonReducer.forms.customerFormData,
        dropdownlists: state.CommonReducer.dropdownlists,
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customerConnections: state.CustomerReducer.customerConnections
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OldCustomerForm));