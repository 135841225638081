import helpers from "../../../../Utils/helpers";

export function CustomerSubscriptionPeriodContainer({ ...props }) {
    const { subscription } = props;
    return <div className="card-list card-list-secondary">
        <div className="card-list-item d-flex flex-column align-items-start">
            <SubscriptionPeriod period={subscription.bindingPeriod} label={'Binding period'} />
            <SubscriptionPeriod period={subscription.subscriptionBillingPeriod} label={'Subscription billing period'} />
            <SubscriptionPeriod period={subscription.usageBillingPeriod} label={'Usage billing period'} />
        </div>
    </div>
}

function SubscriptionPeriod({ label, period }) {
    return <div>
        <p className="bold mb-0">{label}</p>
        <div className="d-grid gap-24">
            <p>{helpers.formatDate(period.startDate)} - {helpers.formatDate(period.endDate)}</p>
        </div>
    </div>
}