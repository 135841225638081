import { httpFetch } from "../../Utils/httpUtils";
import { packageActionTypes as actionTypes } from "../../ActionTypes/Voss/packageActionTypes";
import { commonActionTypes } from "../../ActionTypes/commonActionTypes";

const packageActions = {

    getPackages: () => {
        return (dispatch) => {
            return httpFetch(`/api/packages/GetPackages`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_PACKAGES, data: json });
                })
        }
    },

    getPackage: (id) => {
        return (dispatch) => {
            dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "package", value: true });
            return httpFetch(`/api/packages/GetPackage/${id}`, dispatch)
                .then(json => {
                    dispatch({ type: commonActionTypes.SET_DATA_LOADING, key: "package", value: false });
                    dispatch({ type: actionTypes.STORE_PACKAGE, data: json });
                })
        }
    },

    getPackageNames: () => {
        return (dispatch) => {
            return httpFetch(`/api/packages/GetPackageNames`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_PACKAGE_NAMES, data: json });
                })
        }
    },

    getProductNames: () => {
        return (dispatch) => {
            return httpFetch(`/api/packages/GetProductNames`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_PRODUCT_NAMES, data: json });
                })
        }
    },

    clear: (key) => {
        return (dispatch) => {
            dispatch({ type: actionTypes.CLEAR, key });
        }
    },
};

export default packageActions;
