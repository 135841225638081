import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import commonActions from "../../Actions/commonActions";
import customerActions from "../../Actions/customerActions";
import ConfirmModal from "./ConfirmModal";

function Edit2faModal ({customer, customerActions, onClose, saving }) {
    const { t } = useTranslation();
    const [confirmation, setConfirmation] = useState(null);

    useEffect(() => {
        setConfirmation( {
            onConfirmed: () => customerActions.save2fa(customer.vonCustomer, onClose),
            confirmTitle: t('edit2famodal.twofa'),
            confirmTextElement: customer.vonCustomer?.useTotp ? t('edit2famodal.deactivate') : t('edit2famodal.activate'),
            onCancel: onClose
        })
        
        commonActions.showModal(true);
    }, []);
    
    return confirmation && <ConfirmModal {...confirmation}/>
    
    return <ConfirmModal 
                title={t('edit2famodal.twofa')} 
                confirmationText={customer.vonCustomer?.useTotp ? t('edit2famodal.deactivate') : t('edit2famodal.activate')} 
                mainButtonTitle={customer.vonCustomer?.useTotp? t('common.yes') : t('edit2famodal.safetyfirst')} 
                onClose={onClose}
                onSave={handleClickSave}
                saving={saving}/>
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.customer,
        saving: state.CommonReducer.dataLoading.save2fa
    }
}


function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Edit2faModal));
