import { ScheduledSubscriptionChanges } from "../../../../Components/Customer/VOSS/Subscriptions/ScheduledSubscriptionChanges";
import { CustomerSubscriptionPeriodContainer } from "./CustomerSubscriptionPeriodContainer";
import Card from "../../../../Components/Card/Card";
import { useNavigate } from "react-router-dom";
import routes from "../../../../Constants/routes";
import { EditSubscriptionForm } from "../../../../Components/Customer/VOSS/Subscriptions/EditSubscriptionForm";
import { ChangeSubscriptionTierContainer } from "./ChangeSubscriptionTierContainer";

export function EditSubscriptionContainer({ ...props }) {
    const { subscriptionForm, subscriptionActions, subscriptionLoading, packageLoading, subscription, customer, isModalActive } = props;
    const navigate = useNavigate();

    const onSave = () => {
        subscriptionActions.modifySubscription(subscriptionForm, () => subscriptionActions.getSubscription(subscription.id));
    }

    let isLoading = subscriptionLoading || packageLoading || !subscriptionForm;

    return <>
        <Card>
            <Card.Header>
                <Card.Header.Title>
                    Subscription
                </Card.Header.Title>
            </Card.Header>
            <Card.Content isLoading={isLoading}>
                <div>
                    <div className="d-flex">
                        <div className="d-grid gap-24 col-8 pl-0">
                            <EditSubscriptionForm {...props} />
                            <ScheduledSubscriptionChanges {...props} />
                        </div>
                        <div className="ml-auto">
                            <CustomerSubscriptionPeriodContainer {...props} />
                        </div>
                    </div>
                </div>
            </Card.Content>
            <Card.Footer>
                <Card.Footer.Buttons>
                    <button className="btn btn-primary" onClick={() => navigate(routes.customerMain(customer.pathId))}>Cancel</button>
                    <button className="btn btn-primary active" disabled={!subscriptionForm.changed} onClick={() => onSave()}>Save</button>
                </Card.Footer.Buttons>
            </Card.Footer>
        </Card>
        {isModalActive('selectTier') && <ChangeSubscriptionTierContainer {...props} />}
    </>
}