export const customerActionTypes = {
    SHOW_CUSTOMER : "SHOW_CUSTOMER",
    RESET : "RESET",
    SHOW_VON_ACCOUNTS: "SHOW_VON_ACCOUNTS",
    CUSTOMER_DATA_STATUS: "CUSTOMER_DATA_STATUS",
    UPDATE_CUSTOMER_APPOINTMENTS_COUNT: "UPDATE_CUSTOMER_APPOINTMENTS_COUNT",
    UPDATE_CUSTOMER_LEADS_COUNT: "UPDATE_CUSTOMER_LEADS_COUNT",
    UPDATE_CUSTOMER_AOLEADS_COUNT: "UPDATE_CUSTOMER_AOLEADS_COUNT",
    SELECT_ADMIN_FORM: "SELECT_ADMIN_FORM",
    STORE_INVOICES: "STORE_INVOICES",
    STORE_ATTENDED_EDUCATIONS: "STORE_ATTENDED_EDUCATIONS",
    STORE_ATTENDED_ONBOARDINGS: "STORE_ATTENDED_ONBOARDINGS",
    STORE_EDUCATIONS: "STORE_EDUCATIONS",
    STORE_EDUCATION: "STORE_EDUCATION",
    STORE_COURSE: "STORE_COURSE",
    STORE_BOKAMERA_SERVICES: "STORE_BOKAMERA_SERVICES",
    STORE_BOKAMERA_TIMES: "STORE_BOKAMERA_TIMES",
    STORE_BOKAMERA_CUSTOMFIELDS: "STORE_BOKAMERA_CUSTOMFIELDS",
    GET_DESKTOP_LICENSES: "GET_DESKTOP_LICENSES",
    STORE_COLLABORATIONS: "STORE_COLLABORATIONS",
    STORE_CUSTOMER_DISCOUNTS: "STORE_CUSTOMER_DISCOUNTS",
    STORE_VON_DISCOUNTS: "STORE_VON_DISCOUNTS",
    STORE_PENDING_TERMINATION_REQUESTS: "STORE_PENDING_TERMINATION_REQUESTS",
    STORE_RELATED_CUSTOMERS: "STORE_RELATED_CUSTOMERS",
    SET_SELECTED_WEBINAR: "SET_SELECTED_WEBINAR",
    GET_WEBINARS_FOR_SALES_PERSON: "GET_WEBINARS_FOR_SALES_PERSON",
    SET_BOKA_MERA_MODAL_OBJECT: "SET_BOKA_MERA_MODAL_OBJECT",
    CLEAR_BOKAMERA_TIMES: "CLEAR_BOKAMERA_TIMES",
    SET_VON_ACCOUNT_SEARCH_INPUT: "SET_VON_ACCOUNT_SEARCH_INPUT",
    SET_INTERNAL_LEAD_EMAIL_TEMPLATE: "SET_INTERNAL_LEAD_EMAIL_TEMPLATE",
    GET_COMPENSATIONS_FOR_CUSTOMER: "GET_COMPENSATIONS_FOR_CUSTOMER",
    GET_COMPENSATIONS_RESULTS_DROPDOWN: "GET_COMPENSATIONS_RESULTS_DROPDOWN",
    UPDATE_CUSTOMER_COMPENSATION_HISTORY_COUNT: "UPDATE_CUSTOMER_COMPENSATION_HISTORY_COUNT",
    STORE_CAMPAIGNS_FOR_CUSTOMER: "STORE_CAMPAIGNS_FOR_CUSTOMER",
    STORE_VISMA_FINANCE_PRODUCTS: "STORE_VISMA_FINANCE_PRODUCTS",
    SET_VISMA_FINANCE_PRODUCTS_LOADING: "SET_VISMA_FINANCE_PRODUCTS_LOADING",
    STORE_COMPANY_EXPENSE_PRODUCTS: "STORE_COMPANY_EXPENSE_PRODUCTS",
    SET_COMPANY_EXPENSE_PRODUCTS_LOADING: "SET_COMPANY_EXPENSE_PRODUCTS_LOADING",
    STORE_BLIKK_PRODUCTS: "STORE_BLIKK_PRODUCTS",
    SET_VCE_EMAIL_TEMPLATE: "SET_VCE_EMAIL_TEMPLATE",
    GET_AO_CUSTOMER_INFO: "GET_AO_CUSTOMER_INFO",
    STORE_AO_CUSTOMERS: "STORE_AO_CUSTOMERS",
    CLEAR_AO_CUSTOMERS: "CLEAR_AO_CUSTOMERS",
    CLEAR_CUSTOM_FIELDS: "CLEAR_CUSTOM_FIELDS",
    STORE_SENT_MOVIES: "STORE_SENT_MOVIES",
    CLEAR_COLLABORATIONS: "CLEAR_COLLABORATIONS",
    STORE_EMPLOYEES: "STORE_EMPLOYEES",
    CLEAR_EMPLOYEES: "CLEAR_EMPLOYEES",
    STORE_SUBSCRIPTIONS: "STORE_SUBSCRIPTIONS",
    STORE_VBPRODUCTS: "STORE_VBPRODUCTS",
    STORE_VONPRODUCTS: "STORE_VONPRODUCTS",
    STORE_CONNECTED_CUSTOMERS: "STORE_CONNECTED_CUSTOMERS",
    STORE_TRANSACTION_SERVICES: "STORE_TRANSACTION_SERVICES",
    STORE_INYETT_DATA: "STORE_INYETT_DATA",
    STORE_CUSTOMER_INFO_FLAGS: "STORE_CUSTOMER_INFO_FLAGS",
    CUSTOMER_FROM_BUSINESS_CHECK: "CUSTOMER_FROM_BUSINESS_CHECK",
    STORE_VOSS_CUSTOMER_INVOICE: "STORE_VOSS_CUSTOMER_INVOICE",
    STORE_VOSS_CUSTOMER_INVOICES: "STORE_VOSS_CUSTOMER_INVOICES",
    STORE_ORDER_LIMITATION_REASONS: "STORE_ORDER_LIMITATION_REASONS",
    STORE_VON_CUSTOMER: "STORE_VON_CUSTOMER",
}