
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import React, {useEffect} from "react";
import {MainInfo} from "../../Components/Customer/CustomerComponents/MainInfo";
import commonActions from "../../Actions/commonActions";
import customerActions from "../../Actions/customerActions";
import CustomerInfoFlagsContainer from "./CustomerInfoFlagsContainer";
import {DefaultCard} from "../../Components/Card/Card";
//import verifiedcustomer from "../../Images/verifiedcustomer.svg";
import Tooltip, {TooltipContent, TooltipTrigger} from "../../Components/Overlays/Tooltip";
import enabled from "../../Images/2fa-enabled.svg";
import disabled from "../../Images/2fa-disabled.svg";
import disabledDark from "../../Images/2fa-disabled-dark.svg";

export function MainInfoContainer({ customer, commonActions, toggleModal }) {
    const {t} = useTranslation();

    const onFlagClick = (e, flag) => {
        e.stopPropagation();
        e.preventDefault();
        toggleModal("show2faModal");
    }
    const get2faIcon = () => {
        let logo = enabled;
        let darkmodeLogo = enabled;

        if (!customer.vonCustomer?.useTotp) {
            logo = disabled
            darkmodeLogo = disabledDark
        }
        
        return <>
            <img className='pl-8 cursorpointer show-on-light'
                 alt={"2fa"}
                 src={logo} onClick={(e) => onFlagClick(e)}/>
            <img className='pl-8 cursorpointer show-on-dark'
                 alt={"2fa"}
                 src={darkmodeLogo} onClick={(e) => onFlagClick(e)}/>
        </>
    }

    function getHeader() {
        return (<>
                {/*
                <Tooltip>
                    <TooltipTrigger>
                        {<img className='pl-8' alt={"Verified Customer"} src={verifiedcustomer}/>}
                    </TooltipTrigger>
                    <TooltipContent>
                        <div>{t('maininfocontainer.verifiedcustomer')}</div>
                    </TooltipContent>
                </Tooltip>
                */}
                {customer.vonCustomer && 
                    <Tooltip>
                        <TooltipTrigger>
                            {get2faIcon()}
                        </TooltipTrigger>
                        <TooltipContent>
                            <div>{customer.vonCustomer?.useTotp ? t("maininfocontainer.customeruses2fa") : t("maininfocontainer.cusomerdoesnotuse2fa")}</div>
                        </TooltipContent>
                    </Tooltip>
                    
                }
                <div>
                    <CustomerInfoFlagsContainer onClick={toggleModal}/>
                </div>
            </>
        )
    }

    return <>
        <DefaultCard
            title={t("maininfocontainer.header")}
            headerContent={getHeader()}
            isLoading={!customer}>
            <MainInfo customer={customer.vossCustomer ?? customer.vbCustomer}/>
        </DefaultCard>
    </>
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.customer,
        customerForm: state.CommonReducer.forms && state.CommonReducer.forms.mainInfoFormData,
        customerInfoFlags: state.CustomerReducer.customerInfoFlags
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MainInfoContainer));