import React from "react";
import { useTranslation } from "react-i18next";
import routes from "../../Constants/routes";
import VismaFinanceProduct from "./VismaFinanceProduct";
import { NoInformation } from "../Panel";
import MessageAlertLevel from "../MessageAlertLevel";

export function VismaFinanceProductContainer(props) {

    const { vismaFinance, vismaFinanceProductLoading } = props;
    
    if(vismaFinanceProductLoading)
        return <div className='loader'>Loading...</div>

    if(!vismaFinance)
        return null;
    
    if(!vismaFinance.vismaFinanceResponse)
        return <MessageAlertLevel message="Can´t access Visma Finance. Information is limited." />
    
    if(!vismaFinance.vismaFinanceProducts.length)
        return <NoInformation />
    
    return <>
        {vismaFinance.vismaFinanceProducts && vismaFinance.vismaFinanceProducts.map((item, index) =>
            <VismaFinanceProduct key={index}
                product={item.product}
                status={item.status}
                color={item.color}
                isAllowedToBuyProduct={item.isAllowedToBuyProduct} ></VismaFinanceProduct>
        )}
    </>
}

export function VonAccounts(props) {

    const { t } = useTranslation();
    
    if (!props.vonAccounts) {
        return null;
    }

    const groupVonAccounts = (vonAccounts) => {
        var arr = [];
        vonAccounts.forEach(account => {
            arr.push(...account.roles);
        });

        return arr.reduce((result, role) => {
            const product = role.productName || 'n/a';
            const specifiedProductName = role.specifiedProductName || "";
            var roles = role.role || 'n/a';
            result[product] = result[product] || [];
            result[product].specifiedProductName = specifiedProductName;
            result[product].push(roles);

            return result;

        }, Object.create(null));
    }

    if (props.vonAccounts.length === 0) {
        return (<fieldset className="col-xs-12"><legend>{t('vonaccountscontainer.vonaccounts')}</legend><p>{ }</p></fieldset>);
    }

    const groupedVonAccounts = groupVonAccounts(props.vonAccounts);
    return (<fieldset className="col-xs-12">
        <legend>{t('vonaccountscontainer.vonaccounts')}</legend>
        <ul className="list-group">
            {Object.keys(groupedVonAccounts).map((key, index) => {
                return (
                    <li key={index} className="list-group-item">
                        <span className="badge">{groupedVonAccounts[key].length}</span>
                        {key}
                    </li>
                )
            })}
        </ul>
    </fieldset>
    )
}

export function CollaborationsList(props) {
    const { t } = useTranslation();

    if (!props.collaborations) {
        return null;
    }

    if (props.collaborations.length === 0) {
        return (<fieldset className="col-xs-12"> <legend>{t('vonproducts.collaboration')}</legend><p>{t('collaborationscontainer.noentries')}</p></fieldset>);
    }

    const groupCollaborations = (collaborations) => {
        return collaborations.reduce((result, collaboration) => {
            const product = collaboration.product || 'n/a'
            result[product] = result[product] || [];
            result[product].push(collaboration);

            return result;

        }, Object.create(null));
    }
    
    const groupedCollaborations = groupCollaborations(props.collaborations);
    return ( <fieldset className="col-xs-12">
            <legend>{t('vonproducts.collaboration')}</legend>
            <ul className="list-group">
                {Object.keys(groupedCollaborations).map((key, index) => {
                    return (
                        <li key={index} className="list-group-item">
                            <span className="badge">{groupedCollaborations[key].length}</span>
                            {key}
                        </li>
                    )
                })}
            </ul>
        </fieldset>
    )
}

export function CustomerActionHistory(props) {
    const { t } = useTranslation();
    if(!props.actionLogs) {
        return null;
    }

    if(props.actionLogs.length === 0) {
        return (<fieldset className="col-xs-12"><legend>{t('actionhistory.actionhistory')}</legend><p>{t('actionlogcontainer.noentriesfound')}</p></fieldset>);
    }
    
    return <fieldset className="col-xs-12">
        <legend>{t('actionhistory.actionhistory')}</legend>
        <ul className="list-group">
            {(props.actionLogs).slice(0,5).map((action, index) => {
                return (
                    <li key={index} className="list-group-item">
                        <span>{action.createDate.split(' ')[0]} </span>
                        <span>{action.description} </span>
                        <span>{action.salesPersonName}</span>
                    </li>
                )
            })}
        </ul>
    </fieldset>
}

export function RelatedCustomers(props) {
    const { t } = useTranslation();
    
    if(!props.relatedCustomers) {
        return null;
    }

    if(props.relatedCustomers.length === 0) {
        return <p>{t("relatedcustomerscontainer.error") }</p>
    }
    
    return <ul className="list-group">
            {(props.relatedCustomers).slice(0,10).map((relatedCustomer, index) => {
                return (
                    <li key={index} className="list-group-item">
                        <a href={routes.customerMain(relatedCustomer.customerNumber)} target="_blank" rel="noopener noreferrer">{relatedCustomer.customerNumber}</a>
                        <span> {relatedCustomer.name} </span>
                    </li>
                )
            })}
        </ul>
}

