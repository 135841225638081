import SkynetIcon from "../../Fundamentals/SkynetIcon";
import routes from "../../../Constants/routes";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {targetGroupConfigurations} from "../../../Constants/VOSS/targetGroups";

export function TargetGroupsTable({ targetGroups }) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const navigateToEdit = (id) => {
        const params = new URLSearchParams();
        params.append("Id", id);
        navigate(routes.vossTargetGroupsEdit + "?" + params.toString())
    }

    const renderConfiguration = (configuration) => {
        return Object.keys(targetGroupConfigurations).map(configKey => {
            return <td key={configKey} className="text-center">{configuration[configKey] &&
                <SkynetIcon icon="vismaicon-approved pt-4"/>}</td>
        })
    }

    return <table className="table table-stripe skynet-table-no-divider table-active">
        <thead>
        <tr>
            <th>{t('common.name')}</th>
            {Object.keys(targetGroupConfigurations).map(configKey => {
                return <th key={configKey} className="text-center">{t(`targetgroupsformcontent.${configKey.toLowerCase()}`)}</th>
            })}
        </tr>
        </thead>
        <tbody>
        {targetGroups?.map((group, index) => {
            return <tr key={group.name} onClick={() => navigateToEdit(group.id)}>
                <td className={group.isDefault ? "bold" : ""}>{group.name}</td>
                {renderConfiguration(group.configuration)}
            </tr>
        })}
        </tbody>
    </table>
}