import React, {Component, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import VbProductsContainer from './Products/VbProductsContainer'
import VonProductsContainer from './Products/VonProductsContainer'
import ThirdPartyProductsContainer from './Products/ThirdPartyProductsContainer'
import VismaVceModal from '../../Components/Customer/VismaVceModal'
import customerActions from '../../Actions/customerActions'
import commonActions from '../../Actions/commonActions'
import { withTranslation } from 'react-i18next';
import InternalLeadSendEmailModal from '../../Components/Customer/SendInternalLeadEmailModal'
import actionLogActions from '../../Actions/actionLogActions';
import AOCustomerInformationModal from './AOCustomerInformationModal';
import {
    VonAccounts,
    CustomerActionHistory,
} from "../../Components/Customer/CustomerComponents";

import AoCustomerInformation from "../../Components/Customer/CustomerComponents/AoCustomerInformation";
import { TabsCard } from '../../Components/Card/TabsCard';


import helpers from "../../Utils/helpers";
import { CardContainer, CardRow, DefaultCard} from '../../Components/Card/Card';

import SubscriptionContainer from "./Products/SubscriptionContainer";
import {FeatureFlags, isFeatureEnabled} from "../../Utils/featureFlags";
import MainInfoContainer from "./MainInfoContainer";
import InternalInfoContainer from "./InternalInfoContainer";
import EditExpiryDateModal from "../../Components/Customer/CustomerComponents/EditExpiryDateModal";
import targetGroupActions from "../../Actions/Voss/targetGroupActions";
import InyettDetails from "../../Components/Customer/CustomerComponents/InyettDetails";
import TransactionServices from "../../Components/Customer/CustomerComponents/TransactionServices";
import CustomerConnectionsEditModal from "../../Components/Customer/Modals/CustomersConnectionsEditModal";
import CustomerConnections from "../../Components/Customer/CustomerComponents/CustomerConnections";
import LeadSendEmailModal from '../../Components/Customer/Modals/LeadSendEmailModal';
import Edit2faModal from "../Modals/Edit2faModal";

const availableComponents = {
    "main": { id: "main", title: "maininfocontainer.header" },
    "internal": { id: "internal", title: "internalinfocontainer.header" },
    "inyettdetails": { id: "inyettdetails", title: "inyettdetailscontainer.header" },
    "customerConnections": { id: "customerConnections", title: "customerconnectionscontainer.header" },
    "vbproducts": { id: "vbproducts", title: "vbproductscontainer.header" },
    "saasproducts": { id: "saasproducts", title: "vonproductcontainer.header" },
    //"subscriptions": { id: "subscriptions", title: "subscriptions.header" }, uncomment when VOSS is enabled
    //"lonsmart": { id: "lonsmart", title: "connectedcustomervoncontainer.header" },
    "transactions": { id: "transactions", title: "transactionservices.header" },
    "thirdpartyproducts": { id: "thirdpartyproducts", title: "thirdpartyproductscontainer.thirdpartyproducts" },
    "aocustomerinfo": { id: "aocustomerinfo", title: "aocustomerinformation.cardheader"},
    "vismaFinance": { id: "vismaFinance", title: "vismafinance.header" },
    //"collaborations": { id: "collaborations", title: "vonproducts.collaboration" },
    //"actionhistory": { id: "actionhistory", title: "actionhistory.actionhistory" },
    //"relatedcustomers": { id: "relatedcustomers", title: "relatedcustomerscontainer.relatedcustomer" },
    //"vonaccounts": { id: "vonaccounts", title: "vonaccountscontainer.vonaccounts" },
    "tabscard": { id: "tabscard", title: "tabscard", tabs: [] }
}

const teamLayouts = {
    9196: "smb",
    9583: "smb",
}

const defaultLayout = {
    layoutName: 'default',
    cards: [
        availableComponents.main,
        [
            availableComponents.internal,
            {...availableComponents.tabscard, tabs: [
                    availableComponents.transactions,
                    availableComponents.inyettdetails,
                    availableComponents.customerConnections,
                    availableComponents.aocustomerinfo
                ]
            }
        ],
        availableComponents.saasproducts, //Replace with availableComponents.subscriptions when VOSS is enabled
        availableComponents.vbproducts,
        availableComponents.thirdpartyproducts
    ]
}

const smbLayout = {
    layoutName: 'smb',
    cards: [
        availableComponents.main,
        [
            availableComponents.internal,
            {...availableComponents.tabscard, tabs: [
                    availableComponents.customerConnections,
                    availableComponents.transactions,
                    availableComponents.inyettdetails,
                    availableComponents.aocustomerinfo
                ]
            }
        ],
        availableComponents.saasproducts, //Replace with availableComponents.subscriptions when VOSS is enabled
        availableComponents.vbproducts,
        availableComponents.thirdpartyproducts
    ]
}

export const agreementsLayout = {
    layoutName: 'agreements',
    cards:
        [
            availableComponents.transactions,
            availableComponents.saasproducts, //Replace with availableComponents.subscriptions when VOSS is enabled
            availableComponents.vbproducts,
            availableComponents.thirdpartyproducts,
        ]

}
const layouts = [defaultLayout, smbLayout, agreementsLayout]

const saveSearchHistory = customer => {
    const storage = localStorage.getItem("CustomerSearchHistory");
    const history = storage ? JSON.parse(storage) : [];

    const customerNumber = customer.vbCustomer?.customerNumber ?? customer.vossCustomer?.customerNumber

    const customerSearchHistory = {
        customerNumber: customerNumber,
        organizationNumber: customer.vbCustomer?.organizationNumber ?? customer.vossCustomer?.organizationNumber,
        name: customer.vbCustomer?.name ?? customer.vossCustomer?.name,
    }

    const index = history.findIndex(item => item.customerNumber === customerNumber || item.customerNumber === customerNumber?.toString())

    if (index >= 0)
        history.splice(index, 1);

    if (history.length === 10)
        history.splice(0, 1);

    history.push(customerSearchHistory);

    localStorage.setItem("CustomerSearchHistory", JSON.stringify(history))
}

export function MainContainer({ vbCustomer, customer, user, commonActions, customerActions, customerInfoOption, featureToggles, t, aoCustomerInfo }) {
    const [modalName, setModalName] = useState(null);

    useEffect(() => {
        if (helpers.isAoCustomer(vbCustomer)) {
            commonActions.loadDropdownList("AoSuppliers");
            customerActions.getAOCustomerInfo(vbCustomer.customerNumber);
        }

        saveSearchHistory(customer);
    }, []);

    const toggleModal = (modalName, emailTemplate) => {
        if (emailTemplate)
            customerActions.setInternalLeadsEmailTemplate(emailTemplate);

        commonActions.showModal(!!modalName);
        setModalName(modalName);
    };
    function getTabs(block) {
        let tabs = []
        block.tabs?.map(tab => {
            let component = renderTabs(tab);
            if (component)
                tabs.push({
                    name: tab.id,
                    label: t(tab.title),
                    content: component
                })
        })

        return tabs;
    }
    function renderTabs(block) {
        switch(block.id) {
            case "inyettdetails": return <InyettDetails/>
            case "transactions": return <TransactionServices toggleModal={toggleModal}/>
            case "customerConnections": return <CustomerConnections key={block.id} toggleModal={toggleModal} />
            case "aocustomerinfo": return helpers.isAoCustomer(vbCustomer) && <AoCustomerInformation key={block.id} toggleModal={toggleModal} user={user} aoCustomerInfo={aoCustomerInfo}/>
            default: return <></>
        }
    }
    function renderCards(block) {
        switch (block.id) {
            case "main": return <MainInfoContainer key={block.id} toggleModal={toggleModal} />
            case "internal": return <InternalInfoContainer key={block.id} toggleModal={toggleModal}/>
            case "transactions": return <DefaultCard title={t(block.title)}><TransactionServices toggleModal={toggleModal}/></DefaultCard>
            //case "vismaFinance": return <VismaFinanceProductContainer key={block.id} />
            case "vbproducts": return <VbProductsContainer key={block.id} />
            //case "lonsmart": return <LonSmart key={block.id} />
            case "saasproducts": return isFeatureEnabled(user, FeatureFlags.VOSS) ? <SubscriptionContainer key={block.id} /> :  <VonProductsContainer key={block.id} /> // TODO: Remove this row when VOSS is enabled
            case "subscriptions": return <SubscriptionContainer key={block.id} />
            case "thirdpartyproducts": return <ThirdPartyProductsContainer key={block.id} />
            //case "collaborations": return <CollaborationsList key={block.id} />
            case "actionhistory": return <CustomerActionHistory key={block.id} />
            //case "relatedcustomers": return <RelatedCustomers key={block.id} />
            case "vonaccounts": return <VonAccounts key={block.id} />
            case "tabscard": {
                let tabs = getTabs(block)
                return <TabsCard key={block.id} tabs={tabs} initialTab={tabs[0].name}></TabsCard>
            }
            default: return <></>
        }
    }
    function renderCardRow(row, index) {
        const hasColumns = row instanceof Array

        let id = row.id ? row.id : row.map(c => c.id)?.join('');
        return (<CardRow orientation={hasColumns ? "horizontal" : ""} key={id}>
            {hasColumns ? row.map((card) => { return renderCards(card)}): renderCards(row)}
        </CardRow>)
    }
    function renderContainer(row) {
        return (<>
                <CardContainer>
                    {row.map((row, index) => renderCardRow(row, index))}
                </CardContainer>
            </>
        )
    }

    function getLayoutName() {
        if (!customerInfoOption)
            return teamLayouts[user?.teamId];

        return customerInfoOption
    }

    function selectLayout() {

        let layoutName = getLayoutName();
        if (!layoutName)
            return defaultLayout

        let selectedLayout = layouts.find(e => e.layoutName === layoutName)

        if (selectedLayout)
            return selectedLayout
        else
            return defaultLayout
    }

        const layout = selectLayout();

    return (
        <>
            {modalName === "showLeadSendEmailModal" && <LeadSendEmailModal onClose={toggleModal} />}
            {modalName === "showVismaVceModal" && <VismaVceModal onClose={toggleModal} />}
            {modalName === "showInternalLeadEmailModal" && <InternalLeadSendEmailModal onClose={toggleModal} />}
            {modalName === "editAOCustomerInfo" && <AOCustomerInformationModal onClose={toggleModal} />}
            {modalName === "editExpiryDateModal" && <EditExpiryDateModal onClose={toggleModal} />}
            {modalName === "editCustomerConnectionsModal" && <CustomerConnectionsEditModal onClose={toggleModal}/>}
            {modalName === "show2faModal" && <Edit2faModal onClose={toggleModal} />}
            {renderContainer(layout.cards)}
        </>)
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customer: state.CustomerReducer.customer,
        aoCustomerInfo: state.CustomerReducer.aoCustomerInfo,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        actionLogActions: bindActionCreators(actionLogActions, dispatch),
        targetGroupActions: bindActionCreators(targetGroupActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MainContainer));