import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import commonActions from '../../Actions/commonActions';
import ReactModal from 'react-modal';

function ConfirmModal({commonActions, showModal, confirmTitle, confirmTextElement, onConfirmed, onCancel, buttonTexts, t}) {

    const handleModalConfirm = (e) => {
        if(e.target.value === 'true'){
            onConfirmed();
        } else if (onCancel) {
            onCancel();
        }
        
        commonActions.showModal(false);
        return;
    }
    //

    if (!buttonTexts)
        buttonTexts = [t('common.confirm'), t('common.cancel')];
    
    return (
        <ReactModal isOpen={showModal} style={
            {
                content: {
                    overflowY: 'auto',

                },
                overlay: {
                    backgroundColor: 'rgba(40, 40, 40, 0.5)'
                },
                zindex: 9999,
            }
        } ariaHideApp={false} className="modalCenter modal fade in" >
                <div className={"modal-dialog "}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className='modal-title'>{confirmTitle}</h4>
                        </div>
                        <div className="modal-body">
                            {confirmTextElement ?? <p>{t('confirmmodal.confirmaction')}</p>}
                        </div>
                        <div className="modal-footer mt-auto justify-content-end d-flex">
                            <button className="btn btn-default" value={false}
                                    onClick={(e) => handleModalConfirm(e)}>{buttonTexts[1]}</button>
                            <button className="btn btn-primary" value={true}
                                    onClick={(e) => handleModalConfirm(e)}>{buttonTexts[0]}</button>
                        </div>
                    </div>
                </div>
        </ReactModal>
    )
}

function mapStateToProps(state) {
    return {
        showModal: state.CommonReducer.showModal,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConfirmModal));