
import React, {useEffect} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import routes from "../../../Constants/routes";
import SkynetIcon from "../../Fundamentals/SkynetIcon";
import {bindActionCreators} from "redux";
import customerActions from "../../../Actions/customerActions";
import {connect} from "react-redux";
import {productDropdown} from "../../../Constants/dropDownLists";

export function CustomerConnectionType({customer}) {
    const {t} = useTranslation();
    
    if (customer.type === null) return;
    
    let icon = "user";
    let text = "main";

    if (customer.type !== 0) {
        icon = "users";
        text = "sub";
    }

    return <><SkynetIcon icon={`vismaicon-sm vismaicon-${icon} pt-2 mr-8`} title={t("customerconnections."+text)}/>{t("customerconnections."+text)}</>
}

function CustomerConnectionRow({customer}) {

    if (!customer) return null;
    
    const getProduct = (product) => {
        return productDropdown.filter(p => p.id === product)[0]?.name;
    }

    return (
        <tr key={customer.customerNumber} onClick={() => window.open(routes.customerMain(customer.customerNumber), '_blank')} >
            <td><CustomerConnectionType customer={customer}/></td>
            <td>{customer.name}</td>
            <td>{customer.customerNumber}</td>
            <td>{customer.organizationNumber}</td>
            <td>{getProduct(customer.product)}</td>
        </tr>
    )
}

export function CustomerConnectionsTable({customerConnections}) {
    const {t} = useTranslation();
    
    return <table className="table table-stripe table-hover">
        <thead>
        <tr className="bold">
            <th>{t('customerconnections.type')}</th>
            <th>{t('customerconnections.customer')}</th>
            <th>{t('customerconnections.custno')}</th>
            <th>{t('customerconnections.orgno')}</th>
            <th>{t('customerconnections.product')}</th>
        </tr>
        </thead>
        <tbody>
            {customerConnections?.map((customer, index) => {
                return <CustomerConnectionRow key={index} customer={customer} />
            })}
        </tbody>
    </table>
}

function CustomerConnections({vbCustomer, customerConnections, dataLoading, customerActions, toggleModal}) {
    const {t} = useTranslation();

    useEffect(() => {
        if (!customerConnections && !dataLoading) {
            customerActions.getCustomerConnections(vbCustomer.customerNumber);
        }
    }, []);

    if (dataLoading) {
        return <div className="spinner spinner-default-green m-auto d-block"/>
    }
    return (
        <div>
            <CustomerConnectionsTable customerConnections={customerConnections}/>
            <button type="button" className="pull-right btn btn-default"
                    onClick={() => toggleModal("editCustomerConnectionsModal")}>{t('common.edit')}</button>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        customerConnections: state.CustomerReducer.customerConnections,
        vbCustomer: state.CustomerReducer.selectedCustomer,
        dataLoading: state.CommonReducer.dataLoading.customerConnections
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerConnections));