import { leadActionTypes as actionTypes } from '../ActionTypes/leadActionTypes'
import { commonActionTypes } from '../ActionTypes/commonActionTypes'

export default function LeadsReducer(state = {}, action) {

    let newState;

    switch (action.type) {
        case actionTypes.STORE_MY_LEADS:
            newState = { myLeads: action.data }
            break;
        case actionTypes.STORE_MY_CLOSED_LEADS:
            newState = { myClosedLeads: action.data }
            break;
        case actionTypes.STORE_MY_EXTERNAL_PARTNERS:
            newState = { externalPartners: action.data }
            break;
        case actionTypes.STORE_MY_CUSTOMER_LEADS:
            newState = { myCustomerLeads: action.data }
            break;
        case actionTypes.STORE_LEADS:
            newState = { leads: action.data }
            break;
        case actionTypes.STORE_LEAD:
            newState = { lead: action.data }
            break;
        case actionTypes.STORE_CUSTOMER_LEADS:
            newState = { customerLeads: action.data }
            break;
        case actionTypes.UPDATE_LEADS_COUNT:
            newState = { openLeadsCount: action.openLeadsCount }
            break;
        case actionTypes.CLEAR_LEAD:
            newState = { lead: null }
            break;
        case actionTypes.CLEAR_LEADS:
            newState = { myLeads: null, leads: null }
            break;
        case actionTypes.STORE_LEAD_TYPES:
            newState = { leadTypes: action.data }
            break;
        case actionTypes.STORE_SEARCH_MY_LEADS_RESULTS:
            newState = { myLeadSearchResults: action.data }
            break;
        case actionTypes.STORE_CUSTOMERS_FOR_LEAD_EMAIL:
            newState = { leadCustomers: action.data.results }
            break;
        case actionTypes.CUSTOMER_TRIALS:
            newState = { leadTrial: action.data }
            break;
        case actionTypes.CLEAR_SEARCH_RESULTS:
            newState = { myLeadSearchResults: null }
            break;
        case actionTypes.SELECTED_LEADS:
            newState = { selectedLeads: Object.assign([], action.data) }
            break;
        case actionTypes.CLEAR_SELECTED_LEADS:
            newState = { selectedLeads: [] }
            break;
        case commonActionTypes.SORTED_LIST:
            if (action.listName === "myLeads")
                newState = { myLeads: action.list }
            if (action.listName === "aoLeads")
                newState = { aoLeads: action.list }
            if (action.listName === "internalLeadSearchResults")
                newState = { internalLeadSearchResults: action.list }
            if (action.listName === 'internalLeads')
                newState = { internalLeads: action.list}
            break;
        case actionTypes.STORE_AO_LEADS:
            newState = { aoLeads: action.data }
            break;
        case actionTypes.STORE_OPEN_AO_LEADS:
            newState = { openAoLeads: action.data }
            break;
        case actionTypes.STORE_INTERNAL_LEADS:
            newState = { internalLeads: action.data }
            break;
        case actionTypes.STORE_AO_SALES_PERSONS:
            newState = { aoSalesPersons: action.data }
            break;
        case actionTypes.STORE_INTERNAL_EMAILS:
            newState = { internalEmails: action.data }
            break;
        case actionTypes.STORE_AO_LEAD:
            newState = { aoLead: action.data }
            break;
        case actionTypes.CLEAR_AO_LEAD:
            newState = { aoLead: null }
            break;
        case actionTypes.CLEAR_INTERNAL_LEAD:
            newState = {internalLead: null}
            break;
        case actionTypes.STORE_INTERNAL_LEAD:
            newState = {internalLead: action.data }
            break;
        case actionTypes.STORE_AO_LEADS_SEARCH_RESULTS:
            newState = { aoLeadSearchResults: action.data }
            break;
        case actionTypes.CLEAR_AO_LEADS_SEARCH_RESULTS:
            newState = { aoLeadSearchResults: null }
            break;
        case actionTypes.STORE_INTERNAL_LEADS_SEARCH_RESULTS:
            newState = { internalLeadSearchResults: action.data }
            break;
        case actionTypes.CLEAR_INTERNAL_LEADS_SEARCH_RESULTS:
            newState = { internalLeadSearchResults: null }
            break;
        case actionTypes.STORE_AO_CUSTOMER_LEADS:
            newState = { aoCustomerLeads: action.data }
            break;
        case actionTypes.UPDATE_AO_LEADS_LIST:
            const lead = action.data
            const leads = state.aoLeads

            const index = leads.findIndex(item => item.id === lead.id);

            leads[index] = lead;

            newState = { aoLeads: [...leads] }
            break;
        case actionTypes.UPDATE_INTERNAL_LEADS_LIST:
            const internalLead = action.data
            const internalLeadsList = state.internalLeads

            const leadIndex = internalLeadsList.findIndex(item => item.id === internalLead.id);

            internalLeadsList[leadIndex] = internalLead;

            newState = { internalLeads: [...internalLeadsList] }
            break;
        default:
            return state
    }

    if (newState)
        return Object.assign({}, state, newState)

    return state;
}